import axios from "axios";
import { compact } from "lodash";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { applyMiddleware, compose, createStore, Store } from "redux";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import ReduxPromise from "redux-promise";
import thunk from "redux-thunk";
import packageJson from "../../package.json";
import { AUTH_URL, HEADER_SERVICE } from "../config";
import AppDefault from "../containers/AppDefault";
import AppWithNavbar from "../containers/AppWithNavbar";
import RouteWithLayout from "../containers/RouteWithLayout";
import ContextProvider from "../contexts/ContextProvider";
import { kibanaUrlHost } from "../helpers/util";
import reducers from "../reducers";
import "../styles/App.scss";
import { LoginType } from "../types/auth";
import Apm from "./Apm";
import GoogleAnalytics from "./common/GoogleAnalytics";
import GrowerLoginPage from "./GrowerLogin/GrowerLoginPage";
import InstallInstructionsPage from "./InstallInstructions/InstallInstructions";
import LoginPage from "./Login/LoginPage";
import LogoutPage from "./Logout/LogoutPage";
import ManagementAreasPage from "./ManagementAreasPage/ManagementAreasPage";
import PropertyGroupPage from "./PropertyGroupPage/PropertyGroupPage";
import RunNumberPage from "./RunNumberPage/RunNumberPage";
import TrapPage from "./TrapPage/TrapPage";

const persistConfig = {
  key: "root",
  storage,
  debug: true,
};

const { REACT_APP_DEBUG } = process.env;

const persistedReducer = persistReducer(persistConfig, reducers);
const reduxLogger = false && REACT_APP_DEBUG && logger;
const middleware = compact([ReduxPromise, thunk, reduxLogger]);
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store: Store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
const persistor = persistStore(store);

const App: React.FC<{}> = () => {
  axios.interceptors.request.use(
    (config) => {
      // Return unmodified config when request url matched a pattern in 'ignorePatterns'.
      const ignorePatterns = [kibanaUrlHost];
      let ignore = false;
      ignorePatterns.forEach((pattern) => {
        if (config.url?.includes(pattern)) {
          ignore = true;
        }
      });
      if (ignore) return config;
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      // Attach the app version header.
      config.headers["Version"] = packageJson.version;
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const auth: IAuthReducer = store.getState().auth;
      const originalRequest = error.config;
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Log the user out and reject
      if (error.response?.status === 401 && !originalRequest._retry) {
        const refreshToken = localStorage.getItem("refreshToken");
        originalRequest._retry = true;
        const headers =
          auth.loginType === LoginType.Credentials
            ? { service: HEADER_SERVICE }
            : { service: auth.service };
        return axios
          .post(
            `${AUTH_URL}/token/jwt/refresh`,
            {
              refresh_token: refreshToken,
            },
            { headers }
          )
          .then((res) => {
            if (res.status === 200) {
              // 1. put token to LocalStorage
              localStorage.setItem("accessToken", res.data.jwt_token);
              localStorage.setItem("refreshToken", res.data.refresh_token);
              // 2. Change Authorization header
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
              // 3. return originalRequest object with Axios.
              return axios(originalRequest);
            }
          });
      }
      return error;
    }
  );

  return (
    <ContextProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Apm />
          <Router>
            <GoogleAnalytics />
            <Switch>
              <RouteWithLayout
                layout={AppWithNavbar}
                path="/property-group/:id/property/:property/zone/:zone/trap/:trap"
                component={TrapPage}
                requiresAuth
              />
              <RouteWithLayout
                layout={AppWithNavbar}
                path="/property-group/:id/property/:property"
                component={ManagementAreasPage}
                requiresAuth
              />
              <RouteWithLayout
                layout={AppWithNavbar}
                path="/property-group/:id"
                component={PropertyGroupPage}
                hideBackButton
                requiresAuth
              />
              <RouteWithLayout
                layout={AppDefault}
                path="/login"
                component={LoginPage}
              />
              <RouteWithLayout
                layout={AppDefault}
                path="/:tenant/grower-login"
                component={GrowerLoginPage}
              />
              <RouteWithLayout
                layout={AppDefault}
                path="/logout"
                component={LogoutPage}
              />
              <RouteWithLayout
                layout={AppWithNavbar}
                path="/how-to-install"
                component={InstallInstructionsPage}
                // Auth route because 'install' button only accessable from dropdown
                // which is only available from auth routes.
                requiresAuth
              />
              <RouteWithLayout
                layout={AppWithNavbar}
                path="/"
                component={RunNumberPage}
                requiresAuth
              />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </ContextProvider>
  );
};

export default App;
