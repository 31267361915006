import { isEmpty, size } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Detector } from "react-detect-offline";
import Icon from "react-icons-kit";
import {
  arrowLeft,
  menu,
  refreshCcw,
  wifi,
  wifiOff,
  x,
} from "react-icons-kit/feather";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Label,
  Nav,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarText,
  Row,
} from "reactstrap";
import { compose } from "redux";
import packageJson from "../../../package.json";
import { Logout } from "../../actions/auth/Auth";
import { GetAllLures } from "../../actions/lures/Lures";
import { GetPropertyGroup } from "../../actions/properties/Properties";
import { FindRun, UpdateRunNumber } from "../../actions/runs/Runs";
import {
  ClearSyncQueue,
  GetAllTrapStatuses,
  GetTrapInfo,
  SyncOfflineUpdates,
  SyncRetry,
  UpdateTrapCache,
} from "../../actions/traps/Traps";
import { TraplogApiError } from "../../helpers/errors";
import { logTraplogApiError } from "../../helpers/logging";
import { formatTenantName, hardReload } from "../../helpers/util";
import { useGetContext } from "../../hooks/useGetContext";
import useLocalStorageState from "../../hooks/useLocalStorageState";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import { useSync } from "../../hooks/useSync";
import "../../styles/Header.scss";
import { LoginType } from "../../types/auth";
import { PastRuns } from "../../types/run";
import InstallPWAButton from "./InstallPWAButton";
import RunNumberSelect from "./RunNumberSelect";
import SyncConflictModal, { ModalState } from "./SyncConflictModal";

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { hideBackButton } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [syncConflictModalState, setSyncConflictModalState] = useState({
    open: false,
    conflictingRecords: [],
    remainingRecords: { postUpdates: {}, putUpdates: {} },
  } as ModalState);
  const [formLoading, setFormLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [clickCount, setClickCount] = useState<number>(1);
  const [clickTimeout, setClickTimeout] = useState<
    NodeJS.Timeout | undefined
  >();
  const history = useHistory();
  const { theme } = useGetContext();
  const [pastRuns, setPastRuns] = useLocalStorageState<PastRuns>(
    "pastRuns",
    JSON.parse(localStorage.getItem("pastRuns") || "[]") as PastRuns
  );
  const isOnline = useOnlineStatus();
  const syncOffline = useSync();

  // Set state when header open to update runs selector.
  useEffect(() => {
    if (open) {
      setPastRuns(
        JSON.parse(localStorage.getItem("pastRuns") || "[]") as PastRuns
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const tenantName = props.auth.tenantName;
  const loginType = props.auth.loginType;

  const toggleMenu = () => {
    setOpen(!open);
  };

  const logout = () => {
    history.push("/logout");
  };

  const updateRun = async (runNumber: string | null) => {
    setFormLoading(true);
    try {
      const data = await props.FindRun(props.auth, runNumber);
      const propertyGroup = data.property_group;
      const group = data?.property_group_id;
      if (!group) throw new Error(`Could not load run number ${runNumber}`);
      if (!pastRuns.find((pr) => pr.passcode === data.passcode)) {
        setPastRuns([
          ...pastRuns,
          {
            passcode: data.passcode,
            name: propertyGroup.name,
            description: propertyGroup.description,
          },
        ]);
      }
      props.UpdateRunNumber(runNumber);
      setFormLoading(false);
      history.push(`/property-group/${group}`);
      toggleMenu();
    } catch (err) {
      logTraplogApiError(err as Error, isOnline);
      if (err instanceof TraplogApiError) {
        const error = err.data;
        if (error.response && error.response.status) {
          const numberString =
            loginType === LoginType.Credentials ? "run number" : "passcode";
          switch (error.response.status) {
            case 403: {
              const errorMessage = `You don't have access to this ${numberString}`;
              toast.error(errorMessage, {
                toastId: 3,
              });
              break;
            }
            case 404: {
              const errorMessage =
                runNumber !== ""
                  ? `Unable to find ${numberString} ${runNumber}.\nPlease try again.`
                  : `Please enter a ${numberString}.`;
              toast.error(errorMessage, {
                toastId: 3,
              });
              break;
            }
            case 429:
              toast.error(
                `Too many attempts. Please wait a while and try again.`,
                {
                  toastId: 3,
                }
              );
              break;
            default:
              toast.error(
                <p className="p-0 m-0">
                  An unknown error has occurred. If this happens again please
                  contact support. <b>(Error code: TA1000)</b>
                </p>,
                { autoClose: false }
              );
              break;
          }
        } else if (error) {
          toast.error(error.message);
        }
      } else {
        toast.error(
          <p className="p-0 m-0">
            An unknown error has occurred. If this happens again please contact
            support. <b>(Error code: TA1010)</b>
          </p>,
          { autoClose: false }
        );
      }
    }
    setFormLoading(false);
  };

  const isRoot = window.location.pathname === "/";

  const onChangeSelect = (
    newValue: SingleValue<{ value: string; label: string }>
  ) => {
    const value = newValue?.value.trim();
    props.UpdateRunNumber(value || "");
  };

  const goBack = (e: React.MouseEvent<any, MouseEvent>) => {
    // stop event bubbling to parent.
    e.stopPropagation();
    e.preventDefault();
    // Typing is a bit broken for this lib, so have to cast as any.
    (history as any).goBack();
  };

  const renderBackButton = () => {
    return (
      <NavbarText>
        <Button outline color="link" onClick={goBack} className="btn-back">
          <Icon icon={arrowLeft} size={24}></Icon>
        </Button>
      </NavbarText>
    );
  };

  const syncOfflineUpdates = async (isOnline: boolean) => {
    setSyncing(true);
    syncOffline({
      queue: props.queue,
      auth: props.auth,
      properties: props.properties,
      actions: {
        GetAllTrapStatuses: props.GetAllTrapStatuses,
        GetPropertyGroup: props.GetPropertyGroup,
        GetAllLures: props.GetAllLures,
        GetTrapInfo: props.GetTrapInfo,
        UpdateTrapCache: props.UpdateTrapCache,
        SyncOfflineUpdates: props.SyncOfflineUpdates,
        ClearSyncQueue: props.ClearSyncQueue,
        SyncRetry: props.SyncRetry,
      },
      setSyncConflictModalState,
      isOnline,
    });
    setSyncing(false);
  };

  const onTripleClick = (fn: (...args: any) => void) => {
    setClickCount(clickCount + 1);
    if (clickTimeout) clearTimeout(clickTimeout);
    setClickTimeout(
      setTimeout(() => {
        setClickCount(1);
      }, 2500)
    );
    if (clickCount === 3) {
      fn();
      localStorage.setItem("pastRuns", JSON.stringify([]));
      setClickCount(1);
      if (clickTimeout) clearTimeout(clickTimeout);
    }
  };

  const loggedInUser = () => {
    return props.auth.loginType === LoginType.Credentials
      ? props.auth.name || "Unknown user"
      : props.auth.tenantName
      ? `${formatTenantName(props.auth.tenantName)} Grower`
      : "Unknown Grower";
  };

  return (
    <div>
      <SyncConflictModal
        modalState={[syncConflictModalState, setSyncConflictModalState]}
      />
      <Detector
        render={({ online }: { online: boolean }) => {
          const onlineClass = online ? "online" : "offline";
          const onlineIcon = online ? wifi : wifiOff;
          const menuIcon = open ? x : menu;
          return (
            <>
              <Navbar
                dark
                sticky="true"
                className={`${onlineClass} ${
                  isRoot || hideBackButton ? "home" : ""
                } ${online && tenantName ? "tenant-" + tenantName : ""}`}
              >
                <div className="container">
                  <NavbarBrand href="/">
                    {!isRoot && !hideBackButton && renderBackButton()}
                    <NavbarText>
                      <div className={`connection-indicator ${onlineClass}`}>
                        <Icon icon={onlineIcon} size={24} />
                      </div>
                    </NavbarText>
                    &nbsp; Trappa
                  </NavbarBrand>
                  <Nav className="nav-right">
                    <NavLink>
                      <Button onClick={toggleMenu} color="link">
                        <Icon icon={menuIcon} size={32} />
                      </Button>
                    </NavLink>
                  </Nav>
                </div>
              </Navbar>
              <Collapse
                isOpen={open}
                className={`nav-menu ${onlineClass} ${
                  online && tenantName ? "tenant-" + tenantName : ""
                }`}
                style={{ overflow: "hidden" }}
              >
                <Row className="w-100">
                  <Col xs={12} className="w-100">
                    <Label>
                      <h3>
                        {tenantName === "nzap" ? "Passcode" : "Run Number"}
                      </h3>
                    </Label>
                    <Form className="run-number-form">
                      <FormGroup className="no-margin w-100">
                        <div style={{ width: "100%", height: "100%" }}>
                          <RunNumberSelect
                            pastRuns={pastRuns}
                            setPastRuns={setPastRuns}
                            styles={{
                              option: (base) => ({
                                ...base,
                                color: "white",
                              }),
                            }}
                            theme={(_theme) => ({
                              ..._theme,
                              borderRadius: 0,
                              colors: {
                                ..._theme.colors,
                                neutral0:
                                  theme?.theme.tenantDarkerColour || "#451408",
                                primary25:
                                  theme?.theme.tenantDarkerColour || "#451408",
                                primary: theme?.theme.tenantColour || "#222",
                                neutral50:
                                  tenantName === "nzap" ? "white" : "#fcc6b8",
                                neutral80: "white",
                                neutral90: "white",
                              },
                            })}
                            onChange={onChangeSelect}
                            loginType={loginType}
                            loading={formLoading}
                            onCreate={(value: string) => {
                              return updateRun(value);
                            }}
                          />
                        </div>
                      </FormGroup>
                      <Button
                        className="primary-btn run-number-btn"
                        style={{ background: theme?.theme.tenantDarkerColour }}
                        onClick={() => updateRun(props.run.number)}
                        disabled={isEmpty(props.run.number) || formLoading}
                      >
                        Start
                      </Button>
                    </Form>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={12} className="no-padding">
                    <Form inline className="sync-form">
                      <FormGroup
                        className="no-margin d-block"
                        onClick={() => onTripleClick(props.ClearSyncQueue)}
                      >
                        {isEmpty(props.queue.postUpdates) &&
                        isEmpty(props.queue.putUpdates) ? (
                          <div>
                            <p className="queue-status text-left">Up to date</p>
                          </div>
                        ) : (
                          <div>
                            <p className="queue-status text-left">
                              {size(props.queue.postUpdates) +
                                size(props.queue.putUpdates)}{" "}
                              trap(s) to sync
                            </p>
                          </div>
                        )}
                        <div className="mb-2">
                          <p className="queue-status text-left">
                            Last Sync:{" "}
                            <Badge color="secondary">
                              {props.queue.lastSync
                                ? moment(props.queue.lastSync).fromNow()
                                : "Never"}
                            </Badge>
                          </p>
                        </div>
                      </FormGroup>
                      <Button
                        className="primary-btn run-number-btn no-margin"
                        size="sm"
                        onClick={() => syncOfflineUpdates(online)}
                        disabled={
                          (isEmpty(props.queue.postUpdates) &&
                            isEmpty(props.queue.putUpdates)) ||
                          !online ||
                          syncing
                        }
                      >
                        {props.queue.loading ? (
                          <>Syncing...</>
                        ) : (
                          <>
                            <Icon icon={refreshCcw} /> Sync Now
                          </>
                        )}
                      </Button>
                    </Form>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <InstallPWAButton
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </Col>
                </Row>
                <div className="footer">
                  <div className="logout">
                    <p className="no-margin text-left">
                      You are logged in as:<b>{" " + loggedInUser()}</b>
                    </p>
                    <Button
                      className="primary-btn run-number-btn"
                      style={{ background: theme?.theme.tenantDarkerColour }}
                      onClick={logout}
                    >
                      Logout
                    </Button>
                  </div>
                  <Button
                    style={{ touchAction: "manipulation" }}
                    color="link"
                    className="version"
                    onClick={() => onTripleClick(hardReload)}
                  >
                    <small>Trappa v{packageJson.version}</small>
                  </Button>
                </div>
              </Collapse>
            </>
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = ({
  run,
  auth,
  queue,
  properties,
}: {
  run: IRunReducer;
  auth: IAuthReducer;
  queue: IQueueReducer;
  properties: IPropertyReducer;
}) => {
  return {
    run,
    auth,
    queue,
    properties,
  };
};

export default compose<React.ComponentType<Partial<HeaderProps>>>(
  withRouter,
  connect(mapStateToProps, {
    FindRun,
    UpdateRunNumber,
    Logout,
    SyncOfflineUpdates,
    ClearSyncQueue,
    GetAllTrapStatuses,
    GetPropertyGroup,
    GetAllLures,
    GetTrapInfo,
    UpdateTrapCache,
    SyncRetry,
  })
)(Header);
