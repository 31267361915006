import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";
import { BASE_URL, SEASONAL_DB } from "../../config";
import { buildRequest } from "../../helpers/axios";
import { TraplogApiError } from "../../helpers/errors";
import { LoginType } from "../../types/auth";
import {
  LURES_FETCH_ERROR,
  LURES_FETCH_IN_PROGRESS,
  LURES_FETCH_SUCCESS,
} from "./ActionTypes";

export const GetAllLures = async (auth: IAuthReducer) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LURES_FETCH_IN_PROGRESS,
    });
    const url = `${BASE_URL}/trap-lures`;
    try {
      const headers =
        auth.loginType === LoginType.Credentials
          ? { seasonalDatabase: SEASONAL_DB }
          : { seasonalDatabase: auth.seasonalDatabase };
      const opts = buildRequest("GET", url, null, headers);
      const result = await axios(opts);
      if (!result.data) throw new Error("Invalid response from server");
      dispatch({
        type: LURES_FETCH_SUCCESS,
        payload: result.data as IProperty[],
      });
    } catch (err) {
      dispatch({
        type: LURES_FETCH_ERROR,
        payload: err,
      });
      throw new TraplogApiError(
        url,
        "Failed to get trap lures",
        err as AxiosError
      );
    }
  };
};
