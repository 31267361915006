import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { isObjectLike } from "lodash";
import { Dispatch } from "redux";
import { AUTH_URL, HEADER_SERVICE } from "../../config";
import { parseJwt } from "../../helpers/auth";
import { buildRequest } from "../../helpers/axios";
import { LoginError } from "../../helpers/errors";
import { LoginType } from "../../types/auth";
import {
  AUTH_GET_USER_ERROR,
  AUTH_GET_USER_IN_PROGRESS,
  AUTH_GET_USER_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_IN_PROGRESS,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
} from "./ActionTypes";

export const Logout = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AUTH_LOGOUT,
    });
  };
};

export const GetUser = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AUTH_GET_USER_IN_PROGRESS,
    });
    try {
      // additionally get user data, including token because
      // the state will not yet contain the token
      const userOpts = buildRequest("GET", `${AUTH_URL}/user`);
      const userResult = await axios(userOpts);
      if (!userResult.data) throw new Error("Invalid response from server");
      const user = userResult.data as IUser;
      dispatch({
        type: AUTH_GET_USER_SUCCESS,
        payload: user,
      });
    } catch (err) {
      const error = err as AxiosError;
      let payload = err;
      if (
        isObjectLike(error) &&
        isObjectLike(error.response) &&
        error.response?.data
      ) {
        payload = error.response.data;
      }
      dispatch({
        type: AUTH_GET_USER_ERROR,
        payload,
      });
      throw new LoginError("Login failed", payload);
    }
  };
};

export const Login = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AUTH_LOGIN_IN_PROGRESS,
    });
    const payload = { email, password };
    const url = `${AUTH_URL}/token/jwt/generate`;
    const headers = {
      service: HEADER_SERVICE,
    };
    const request: AxiosRequestConfig = buildRequest(
      "POST",
      url,
      payload,
      headers
    );
    try {
      const authResult = await axios(request);
      if (!authResult.data) throw new Error("Invalid response from server");
      const jwt = parseJwt(authResult.data.jwt_token);
      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        payload: {
          loginType: LoginType.Credentials,
          access: jwt.access,
          accessToken: authResult.data.jwt_token,
          refreshToken: authResult.data.refresh_token,
        },
      });
    } catch (err) {
      const error = err as AxiosError;
      let payload = err;
      if (
        isObjectLike(error) &&
        isObjectLike(error.response) &&
        error.response?.data
      ) {
        payload = error.response.data;
      }
      dispatch({
        type: AUTH_LOGIN_ERROR,
        payload,
      });
      throw new LoginError("Login failed", payload);
    }
  };
};

export const GrowerLogin = (options: {
  tenantName: string;
  service: string;
  seasonalDatabase: string;
}) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AUTH_LOGIN_IN_PROGRESS,
    });
    // Get the users' fingerprint.
    const agent = await FingerprintJS.load();
    const fingerprint = await agent.get();
    const { screenFrame, screenResolution, ...components } =
      fingerprint.components;
    const visitor_id = FingerprintJS.hashComponents(components as any);
    // Generate token using the fingerprint.
    const payload = { visitor_id };
    const url = `${AUTH_URL}/token/jwt/guest/generate`;
    const headers = {
      service: options.service,
      seasonalDatabase: options.seasonalDatabase,
    };
    const request: AxiosRequestConfig = buildRequest(
      "POST",
      url,
      payload,
      headers
    );
    try {
      const authResult = await axios(request);
      if (!authResult.data) throw new Error("Invalid response from server");
      const jwt = parseJwt(authResult.data.jwt_token);
      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        payload: {
          loginType: LoginType.Grower,
          tenantName: options.tenantName,
          service: options.service,
          seasonalDatabase: options.seasonalDatabase,
          access: jwt.access,
          accessToken: authResult.data.jwt_token,
          refreshToken: authResult.data.refresh_token,
        },
      });
    } catch (err) {
      const error = err as AxiosError;
      let payload = err;
      if (
        isObjectLike(error) &&
        isObjectLike(error.response) &&
        error.response?.data
      ) {
        payload = error.response.data;
      }
      dispatch({
        type: AUTH_LOGIN_ERROR,
        payload,
      });
      throw new LoginError("Grower Login failed", payload);
    }
  };
};
