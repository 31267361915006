import React from "react";
import { Col, Row } from "reactstrap";
import { getTenantInfo } from "../../helpers/tenantInfo";
import { formatTenantName } from "../../helpers/util";
import { TenantInfo } from "../../types/auth";

interface Props {
	tenantName: string
}

const Footer = ({ tenantName }: Props) => {
	const tenantInfo: TenantInfo | undefined = getTenantInfo(tenantName);

	return (
		<>
			{tenantInfo ? (
				<>
					<Row className="mt-4">
						<Col>
							<img
								style={{ width: '45%' }}
								alt='nzap_logo'
								src={`https://${tenantName}-metwatch-assets.s3.ap-southeast-2.amazonaws.com/${tenantName}-logo.png`}
							/>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col className="text-center d-block">
							<h5 className="m-0">
								{`For support contact ${formatTenantName(tenantName)}:`}
							</h5>
							<h5 className="m-0 font-weight-light">{tenantInfo?.support.phone}</h5>
							<h5 className="m-0 font-weight-light">{tenantInfo?.support.email}</h5>
						</Col>
					</Row>
				</>
			) : null}
		</>
	)
}

export default Footer;