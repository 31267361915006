import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";
import { BASE_URL, SEASONAL_DB } from "../../config";
import { buildRequest } from "../../helpers/axios";
import { TraplogApiError } from "../../helpers/errors";
import { LoginType } from "../../types/auth";
import {
  RUN_FETCH_ERROR,
  RUN_FETCH_IN_PROGRESS,
  RUN_FETCH_SUCCESS,
  RUN_RESET,
  RUN_UPDATE_NUMBER,
} from "./ActionTypes";

export const FindRun = async (auth: IAuthReducer, id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RUN_FETCH_IN_PROGRESS,
    });
    const loginType = auth.loginType;
    const url =
      loginType === LoginType.Credentials
        ? `${BASE_URL}/trapping-runs/passcode/${id}`
        : `${BASE_URL}/trapping-runs/passcode-restricted/${id}`;
    const headers =
      loginType === LoginType.Credentials
        ? { seasonalDatabase: SEASONAL_DB }
        : { seasonalDatabase: auth.seasonalDatabase };
    const opts = buildRequest("GET", url, null, headers);
    try {
      const result = await axios(opts);
      if (result instanceof Error) throw result;
      if (!result || !result.data)
        throw new Error("Invalid response from server");
      dispatch({
        type: RUN_FETCH_SUCCESS,
        payload: result.data,
      });
      // Return property group ID
      return result?.data || null;
    } catch (err) {
      dispatch({
        type: RUN_FETCH_ERROR,
        payload: err,
      });
      throw new TraplogApiError(
        url,
        "Failed to get trapping run",
        err as AxiosError
      );
    }
  };
};

export const UpdateRunNumber = (runNumber: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: RUN_UPDATE_NUMBER,
      payload: runNumber,
    });
  };
};

export const ResetRun = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: RUN_RESET,
    });
  };
};
