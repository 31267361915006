import React, { useEffect } from "react"
import { connect } from "react-redux"
import apm, { setupApmUser } from "../rum"

interface Props {
  auth: IAuthReducer
}

const Apm = ({ auth }: Props) => {
  // Associate user ID with logs.
  useEffect(
    () =>
      setupApmUser(
        apm,
        auth.id,
        // If no loginType then null. If loginType is 'grower'
        // then 'auth.tenantName' otherwise 'fruition'.
        !auth.loginType
          ? null
          : auth.loginType === "grower"
          ? auth.tenantName
          : "fruition",
        auth.loginType
      ),
    [auth.id, auth.tenantName, auth.loginType]
  )

  return <></>
}

const mapStateToProps = ({ auth }: { auth: IAuthReducer }) => {
  return { auth }
}
export default connect(mapStateToProps)(Apm)
