
export enum LoginType {
	Grower = 'grower',
	Credentials = 'credentials'
}

export type TenantInfo = {
	support: {
		email: string,
		phone: string
	},
	pages: {
		runNumber: {
			supportAlert: {
				text: string
			}
		}
	}
}