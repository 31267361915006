import "../styles/Wrapper.scss";
import { Col, Row } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AppDefault: React.FC<RouteComponentProps> = ({ children }) => {
  return (
    <div style={{ overflow: "hidden" }} className="main">
      <Row>
        <Col xs="12">
          <div className="app-container">
            <div className="app-main">{children}</div>
          </div>
        </Col>
      </Row>
      <ToastContainer position="bottom-center" autoClose={5000} />
    </div>
  );
};

export default AppDefault;
