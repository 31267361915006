export const TRAP_UPDATE_IN_PROGRESS = "TRAP_UPDATE_IN_PROGRESS";
export const TRAP_UPDATE_SUCCESS = "TRAP_UPDATE_SUCCESS";
export const TRAP_UPDATE_ERROR = "TRAP_UPDATE_ERROR";
export const TRAP_UPDATE_PENDING = "TRAP_UPDATE_PENDING";

export const TRAPS_GET_STATUS_IN_PROGRESS = "TRAPS_GET_STATUS_IN_PROGRESS";
export const TRAPS_GET_STATUS_SUCCESS = "TRAPS_GET_STATUS_SUCCESS";
export const TRAPS_GET_STATUS_ERROR = "TRAPS_GET_STATUS_ERROR";

export const TRAP_GET_INFO_SUCCESS = "TRAP_GET_INFO_SUCCESS";
export const TRAP_GET_INFO_IN_PROGRESS = "TRAP_GET_INFO_IN_PROGRESS";
export const TRAP_GET_INFO_ERROR = "TRAP_GET_INFO_ERROR";

export const OFFLINE_SYNC_IN_PROGRESS = "OFFLINE_SYNC_IN_PROGRESS";
export const OFFLINE_SYNC_ERROR = "OFFLINE_SYNC_ERROR";
export const OFFLINE_SYNC_SUCCESS = "OFFLINE_SYNC_SUCCESS"
export const OFFLINE_SYNC_CANCEL = "OFFLINE_SYNC_CANCEL"
export const OFFLINE_SYNC_CLEAR = "OFFLINE_SYNC_CLEAR";
export const OFFLINE_SYNC_REMOVE = "OFFLINE_SYNC_REMOVE";
export const OFFLINE_SYNC_RETRY_INCREMENT = "OFFLINE_SYNC_RETRY_INCREMENT";
export const OFFLINE_SYNC_RETRY_FAILURE = "OFFLINE_SYNC_RETRY_FAILURE";
