import { useContext } from "react";
import { IThemeContext, ThemeContext } from "../contexts/theme";

interface IUseGetContext {
	theme: IThemeContext | null;
}

export const useGetContext = (): IUseGetContext => {
	const theme = useContext(ThemeContext);

	return { theme };
}