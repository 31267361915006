import { debounce, filter, isEmpty, map, orderBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { inbox } from "react-icons-kit/feather";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { GetAllLures } from "../../actions/lures/Lures";
import {
  GetPropertyGroup,
  SelectProperty,
  SelectTrap,
} from "../../actions/properties/Properties";
import { GetAllTrapStatuses, UpdateTrapCache } from "../../actions/traps/Traps";
import { PersistScroll } from "../../actions/ui/UIEvents";
import { logTraplogApiError } from "../../helpers/logging";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import "../../styles/PropertiesPage.scss";
import EmptyState from "../common/EmptyState";
import CollapsibleList from "./CollapsibleList";

const ManagementAreasPage: React.FC<ManagementAreasPageProps> = (
  props: ManagementAreasPageProps
) => {
  const params = useParams<{ id: string; property: string }>();
  const history = useHistory();
  const { pathname } = useLocation();
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const isOnline = useOnlineStatus();
  const tenantName = props.auth.tenantName;

  // Fetches property group properties from the API
  useEffect(() => {
    const fetch = async () => {
      try {
        await props.GetAllTrapStatuses(props.auth, params.id);
        await props.GetPropertyGroup(props.auth, params.id);
        await props.GetAllLures(props.auth);
      } catch (err) {
        logTraplogApiError(err as Error, isOnline);
        if (isOnline) {
          toast.error(
            "Failed to fetch traps. Go back to all orchards to try again."
          );
        }
      } finally {
        await props.UpdateTrapCache();
      }
    };
    const timer = setTimeout(() => fetch(), 2500);
    return () => {
      clearTimeout(timer);
    };
    // We want this to run on mount and not auto-inject dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  // When the properties are initially fetched,
  // update the local filterable list.
  useEffect(() => {
    if (
      params.property &&
      isEmpty(props.properties.activeProperty) &&
      props.properties.properties &&
      !isEmpty(props.properties.properties)
    ) {
      // find the index
      const selectedProperty = props.properties.properties[params.property];
      if (selectedProperty) {
        props.SelectProperty(selectedProperty);
      }
    }
  }, [params.property, props, props.properties.properties]);

  // Restore scroll position
  useEffect(() => {
    if (
      scrollRef &&
      props.ui &&
      props.ui.scrollTop &&
      props.ui.scrollTop[pathname] > 0
    ) {
      scrollRef.scrollTo(0, props.ui.scrollTop[pathname]);
    }
  }, [pathname, props.ui, props.ui.scrollTop, scrollRef]);

  const selectTrap = (item: ITrapListItem | ICollapsibleListItem) => {
    const trap = item.data as ITrap;
    props.SelectTrap(trap);
    history.push(
      `/property-group/${params.id}/property/${params.property}/zone/${trap.trapping_zone_id}/trap/${item.id}`
    );
  };

  // We have to do a few things here to get debouced scroll updates
  // working with useCallback
  const scroll = (e: any | React.UIEvent<HTMLElement, UIEvent>) => {
    const scrollTop = e.target.scrollTop;
    props.PersistScroll(window.location.pathname, scrollTop);
  };

  const debouncedScroll = useCallback(
    debounce((e) => scroll(e), 100),
    []
  );

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    // Important: if event.persist() is not called, an artificial event
    // will be passed down to the debounce function and the scroll position
    // will not be available.
    e.persist();
    debouncedScroll(e);
  };

  const renderTrappingZones = () => {
    const trappingZones = props.properties.activeProperty?.trapping_zones;

    return map(trappingZones, (zone: ITrappingZone) => {
      const trapsInZone = filter(
        props.properties.traps,
        (trap) => trap.trapping_zone_id === zone.id
      );
      // Order traps by code and name.
      const orderedTrapsInZone = orderBy(
        trapsInZone,
        [
          (trap) => Number(trap.code),
          (trap) => {
            const lure = props.properties.lures[trap.trap_lure_id];
            const insectGroup =
              lure && lure.insects && lure.insects[trap.trap_lure_id]
                ? lure.insects[trap.trap_lure_id].insect_group
                : null;
            return insectGroup?.name;
          },
        ],
        "asc"
      );
      const items = map(orderedTrapsInZone, (trap): ITrapListItem => {
        // Find the lure id for this trap
        const lure = props.properties.lures[trap.trap_lure_id];
        const insectGroup =
          lure && lure.insects && lure.insects[trap.trap_lure_id]
            ? lure.insects[trap.trap_lure_id].insect_group
            : null;
        return {
          id: trap.id,
          title: `${trap.code} (${trap.name})`,
          data: { ...trap, lure },
          description: insectGroup ? insectGroup.name : null,
          location: trap.location_description,
          status: trap.status,
        };
      });
      return (
        <CollapsibleList
          key={zone.id}
          title={zone.name}
          collapse={true}
          items={items}
          onItemClick={selectTrap}
        />
      );
    });
  };

  return (
    <div
      className="fill flex top properties"
      onScroll={onScroll}
      ref={(ref) => setScrollRef(ref)}
      style={{ overflowX: "hidden" }}
    >
      <Row>
        <Col xs={12} className="page-header pt-4">
          <h3>
            {tenantName === "nzap" ? "Production Sites" : "Management Areas"}
          </h3>
        </Col>

        <Col xs={12}>
          {props.properties.activeProperty &&
          !isEmpty(props.properties.activeProperty.trapping_zones) ? (
            renderTrappingZones()
          ) : (
            <EmptyState
              message="No traps exist on this property."
              icon={inbox}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  auth,
  run,
  ui,
  properties,
}: {
  auth: IAuthReducer;
  run: IRunReducer;
  ui: IUIReducer;
  properties: IPropertyReducer;
}) => {
  return { auth, ui, run, properties };
};
export default connect(mapStateToProps, {
  GetPropertyGroup,
  GetAllLures,
  SelectProperty,
  SelectTrap,
  GetAllTrapStatuses,
  PersistScroll,
  UpdateTrapCache,
})(ManagementAreasPage);
