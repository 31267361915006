import { forEach } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { ActionCreator, compose } from "redux";
import {
  ClearSyncQueue,
  PushTrapMonitorRecords,
} from "../../actions/traps/Traps";
import { logFailedRecords, logTraplogApiError } from "../../helpers/logging";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import "../../styles/SyncConflictModal.scss";

export type ModalState = {
  open: boolean;
  conflictingRecords: { record: ITrapPost; conflictingRecord: ITrapPost[] }[];
  remainingRecords: {
    postUpdates: { [key: string]: ITrapPost };
    putUpdates: { [key: string]: ITrapPost };
  };
};

interface Props {
  modalState: [ModalState, React.Dispatch<React.SetStateAction<ModalState>>];
  auth: IAuthReducer;
  properties: IPropertyReducer;
  ClearSyncQueue: ActionCreator<any>;
}

const SyncConflictModal = ({
  modalState,
  auth,
  properties,
  ClearSyncQueue,
}: Props) => {
  const isOnline = useOnlineStatus();
  const [state, setState] = modalState;
  const open = state.open;
  const conflictingRecords = state.conflictingRecords;
  const remainingRecords = state.remainingRecords;

  const setOpen = (open: boolean) => {
    setState({ ...state, open });
  };

  return (
    <Modal isOpen={open} centered>
      <ModalHeader>Resolve Conflict</ModalHeader>
      <ModalBody className="pt-0">
        <Row>
          <Col>
            <h6>Someone has already monitorered this trap today.</h6>
            <p>Would you like to overwrite the existing monitoring data?</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className="px-4 float-right"
              color="primary"
              onClick={async () => {
                // Overwrite existing record using PUT request(s).
                const putUpdates: ITrapPost[] = [];
                forEach(conflictingRecords, async (cr) => {
                  const failingRecord = cr.record;
                  const conflictingRecord = {
                    ...failingRecord,
                    id: cr.conflictingRecord[0].id,
                  };
                  putUpdates.push(conflictingRecord);
                });
                // Add remaining records (non-conflicting records) to be updated.
                for (const trapId of Object.keys(remainingRecords.putUpdates)) {
                  const update = remainingRecords.putUpdates[trapId];
                  putUpdates.push(update);
                }
                // Send the update request.
                if (putUpdates.length > 0) {
                  try {
                    await PushTrapMonitorRecords(auth, putUpdates, "PUT");
                    ClearSyncQueue();
                  } catch (err) {
                    logTraplogApiError(err as Error, isOnline);
                    ClearSyncQueue();
                    toast.error("Failed to overwrite record(s).");
                    const updates: { [key: string]: ITrapPost } = {};
                    putUpdates.forEach((update) => {
                      if (!update.id) return;
                      updates[update.id] = update;
                    });
                    await logFailedRecords(properties, auth.id, updates, properties.traps);
                  }
                }
                setOpen(false);
              }}
            >
              Yes
            </Button>
          </Col>
          <Col>
            <Button
              className="px-4 float-left"
              color="secondary"
              onClick={() => {
                // No overwrite so clear queue.
                ClearSyncQueue();
                setOpen(false);
              }}
            >
              No
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  properties,
}: {
  auth: IAuthReducer;
  properties: IPropertyReducer;
}) => {
  return {
    auth,
    properties,
  };
};

export default compose<React.ComponentType<Partial<Props>>>(
  connect(mapStateToProps, {
    ClearSyncQueue,
  })
)(SyncConflictModal);
