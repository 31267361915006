import React from "react";
import Icon from "react-icons-kit";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import "../../styles/EmptyState.scss";

const EmptyState: React.FC<EmptyStateProps> = (props: EmptyStateProps) => {
  const history = useHistory();
  return (
    <div className="empty-state">
      {props.icon && (
        <h1>
          <Icon icon={props.icon} size={64} />
        </h1>
      )}
      <h3>{props.message}</h3>
      {/* Weird cast to any here, but typings for lib are outdated */}
      <Button color="default" onClick={() => (history as any).goBack()}>
        Go Back
      </Button>
    </div>
  );
};
export default EmptyState;
