import React from 'react';
import { Spinner } from "reactstrap";
import '../../styles/LoadingPage.scss';

const LoadingPage: React.FC = () => {
  return (
    <div className="fill flex center loading-page">
      <div className="loading-container">
        <Spinner />
      </div>
    </div>
  );
};

export default LoadingPage;
