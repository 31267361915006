import { ApmBase, init as initApm } from "@elastic/apm-rum"

// Initialises the APM agent as soon as this module is imported.
const apm = initApm({
  serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
  serverUrl: process.env.REACT_APP_APM_URL,
  distributedTracingOrigins: [
    // Remove '/api' from URLs.
    process.env.REACT_APP_TRAPLOG_AUTH_URL!.slice(0, -4),
    process.env.REACT_APP_TRAPLOG_BASE_URL!.slice(0, -4),
    process.env.REACT_APP_TRAPLOG_BASE_URL!.slice(0, -4),
    process.env.REACT_APP_PROPERTIES_BASE_URL!.slice(0, -4),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
});

/**
 * Associates the current APM session with the currently authenticated user.
 *
 * @param apm Current APM object.
 * @param userId Current user ID.
 * @param tenantName Current user tenant name.
 * @param loginType Current user login type.
 */
export function setupApmUser(
  apm: ApmBase,
  userId: number | undefined,
  tenantName: string | null,
  loginType: LoginType | null
) {
  apm.setUserContext({
    id: `${loginType}-${tenantName}-${userId}`,
  })
}

export default apm
