import React from "react";
import { Input } from "reactstrap";
import "../../styles/LimitedTextArea.scss";

const LimitedTextArea: React.FC<LimitedTextAreaProps> = ({
  rows,
  cols,
  value,
  limit,
  disabled,
  onChange,
}) => {
  return (
    <div>
      <Input
        disabled={disabled}
        rows={rows}
        cols={cols}
        type="textarea"
        onChange={(event) => onChange(event.target.value)}
        value={value}
      />
      <p className="notes-counter">
        {value.length}/{limit}
      </p>
    </div>
  );
};

export default LimitedTextArea;
