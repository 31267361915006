import React from "react";
import { Alert, Col, Row } from "reactstrap";
import { getTenantInfo } from "../../helpers/tenantInfo";
import { formatTenantName } from "../../helpers/util";
import { TenantInfo } from "../../types/auth";

interface Props {
	tenantName: string
}

const Footer = ({ tenantName }: Props) => {
	const tenantInfo: TenantInfo | undefined = getTenantInfo(tenantName);

	return tenantInfo ? (
		<Alert color="info">
			<Row className="mt-1 ml-0">
				<Col>
					<p style={{ fontSize: '17px' }}>{tenantInfo.pages.runNumber.supportAlert.text}</p>
				</Col>
			</Row>
			<Row className="mt-1 ml-0">
				<Col className="text-center d-block">
					<p className="m-0 font-weight-bold">
						{`For support contact ${formatTenantName(tenantName)}:`}
					</p>
					<p className="m-0 font-weight-light">{tenantInfo?.support.phone}</p>
					<p className="m-0 font-weight-light">{tenantInfo?.support.email}</p>
				</Col>
			</Row>
		</Alert>
	) : null
}

export default Footer;