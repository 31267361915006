import {
  RUN_FETCH_IN_PROGRESS,
  RUN_FETCH_SUCCESS,
  RUN_FETCH_ERROR,
  RUN_UPDATE_NUMBER,
  RUN_RESET,
} from "../../actions/runs/ActionTypes";

const generateInitialState = (): IRunReducer => {
  const state: IRunReducer = {
    loading: false,
    error: null,
    run: null,
    number: '',
  };
  return state;
};

const INIT = generateInitialState();

export default (state = INIT, action: any): IRunReducer => {
  switch (action.type) {
    case RUN_RESET:
      return {
        ...INIT,
      };
    case RUN_UPDATE_NUMBER:
      return {
        ...state,
        number: action.payload,
      };
    case RUN_FETCH_IN_PROGRESS:
      return {
        ...INIT,
        number: state.number,
        loading: true,
      };
    case RUN_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        run: action.payload,
      };
    case RUN_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
