import {
  OFFLINE_SYNC_CANCEL,
  OFFLINE_SYNC_CLEAR,
  OFFLINE_SYNC_ERROR,
  OFFLINE_SYNC_IN_PROGRESS,
  OFFLINE_SYNC_REMOVE,
  OFFLINE_SYNC_RETRY_INCREMENT,
  OFFLINE_SYNC_SUCCESS,
  TRAP_UPDATE_ERROR,
} from "../../actions/traps/ActionTypes";

export const MAX_SYNC_RETRY_ATTEMPTS = 3;

const generateInitialState = (): IQueueReducer => {
  const state: IQueueReducer = {
    loading: false,
    error: false,
    lastSync: null,
    postUpdates: {},
    putUpdates: {},
    syncRetryCounter: 0,
  };
  return state;
};

const INIT = generateInitialState();

export default (state = INIT, action: any): IQueueReducer => {
  switch (action.type) {
    case TRAP_UPDATE_ERROR:
      const update = action.payload.update as ITrapPost;
      const key = update.method === "POST" ? "postUpdates" : "putUpdates";
      return {
        ...state,
        [key]: {
          ...state[key],
          [action.payload.update.trap_id]: {
            ...action.payload.update,
          },
        },
      };
    case OFFLINE_SYNC_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case OFFLINE_SYNC_SUCCESS:
      return {
        ...INIT,
        loading: false,
        lastSync: new Date(),
      };
    case OFFLINE_SYNC_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case OFFLINE_SYNC_CANCEL:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case OFFLINE_SYNC_CLEAR:
      return {
        ...INIT,
      };
    case OFFLINE_SYNC_REMOVE:
      const updateId = action.payload.updateId as number;
      const postUpdates = state.postUpdates;
      if (postUpdates.hasOwnProperty(updateId)) {
        delete postUpdates[updateId];
      }
      const putUpdates = state.putUpdates;
      if (putUpdates.hasOwnProperty(updateId)) {
        delete putUpdates[updateId];
      }
      return {
        ...state,
        postUpdates,
        putUpdates,
      };
    case OFFLINE_SYNC_RETRY_INCREMENT:
      let syncRetryCounter = state.syncRetryCounter;
      if (syncRetryCounter < MAX_SYNC_RETRY_ATTEMPTS) {
        syncRetryCounter++;
      }
      return {
        ...state,
        syncRetryCounter,
      };
    default:
      return state;
  }
};
