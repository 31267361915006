import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import * as serviceWorker from '../serviceWorker';

const UpdateAppPrompt = () => {
	const [showReload, setShowReload] = useState(false);
	const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

	const onSWUpdate = (registration: ServiceWorkerRegistration) => {
		setShowReload(true);
		setWaitingWorker(registration.waiting);
	};

	const reloadPage = () => {
		waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
		setShowReload(false);
		window.location.reload();
	};

	useEffect(() => {
		serviceWorker.register({ onUpdate: onSWUpdate });
	}, []);

	return (
    <Modal isOpen={showReload} centered style={{ zIndex: 2000 }}>
      <ModalHeader>An app update is available!</ModalHeader>
      <ModalBody>
        <Row>
          <Col className="text-center">
            <Button
              className="run-number-btn"
              color="primary"
              onClick={reloadPage}
            >
              Update Now
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UpdateAppPrompt;
