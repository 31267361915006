import { isEmpty, map } from "lodash";
import { toast } from "react-toastify";
import { ActionCreator } from "redux";
import { ModalState } from "../components/common/SyncConflictModal";
import { preSyncFetch } from "../helpers/axios";
import { SyncError, TraplogApiError } from "../helpers/errors";
import { logFailedRecords, logTraplogApiError } from "../helpers/logging";

interface SyncOfflineParams {
  queue: IQueueReducer;
  auth: IAuthReducer;
  properties: IPropertyReducer;
  actions: {
    GetAllTrapStatuses: ActionCreator<any>;
    GetPropertyGroup: ActionCreator<any>;
    GetAllLures: ActionCreator<any>;
    GetTrapInfo: ActionCreator<any>;
    UpdateTrapCache: ActionCreator<any>;
    SyncOfflineUpdates: ActionCreator<any>;
    ClearSyncQueue: ActionCreator<any>;
    SyncRetry: ActionCreator<any>;
  };
  setSyncConflictModalState: React.Dispatch<React.SetStateAction<ModalState>>;
  isOnline: boolean;
}

export const useSync = () => {
  const handleSyncConflicts = (
    postUpdates: { [key: string]: ITrapPost },
    postError: TraplogApiError | Error | undefined,
    postStatus: number | undefined,
    setSyncConflictModalState: React.Dispatch<React.SetStateAction<ModalState>>
  ) => {
    // Found POST conlifct how display sync conflict modal.
    // Only a POST update can have a conflict.
    if (postStatus === 409) {
      const conflictingRecords = [
        ...(postError as TraplogApiError).data.response?.data
          ?.conflictingRecords,
      ];
      setSyncConflictModalState({
        open: true,
        conflictingRecords,
        remainingRecords: { postUpdates, putUpdates: {} },
      });
    }
  };

  const retry = async (
    properties: IPropertyReducer,
    queue: IQueueReducer,
    actions: SyncOfflineParams["actions"],
    updates: { [key: string]: ITrapPost },
    userId: number
  ) => {
    // Get retry state (limit reached or not).
    const retryLimitReached = await actions.SyncRetry(queue, {
      ...updates,
    });
    // Display appropriate error message depending on retry state.
    if (retryLimitReached) {
      await actions.ClearSyncQueue();
      toast.error(`The sync is continuing to fail.`, {
        toastId: 4,
      });
      await logFailedRecords(properties, userId, updates, properties.traps);
    } else {
      const errorMessage =
        queue.syncRetryCounter === 0
          ? "Sync failed. Use the 'Sync Now' button from the drop-down menu."
          : "Sync failed again. Try again or contact support.";
      toast.error(errorMessage, {
        toastId: `${queue.syncRetryCounter}-4`,
      });
    }
  };

  const handleSyncRetry = async (
    properties: IPropertyReducer,
    queue: IQueueReducer,
    actions: SyncOfflineParams["actions"],
    postUpdates: { [key: string]: ITrapPost },
    putUpdates: { [key: string]: ITrapPost },
    postStatus: number | undefined,
    putStatus: number | undefined,
    userId: number
  ) => {
    if (postStatus === 409 || putStatus === 409) {
      throw new Error("An unhandled conflict was found.");
    }
    // Both POST and PUT updates require a retry.
    if (postStatus && postStatus >= 400 && putStatus && postStatus >= 400) {
      await retry(
        properties,
        queue,
        actions,
        { ...postUpdates, ...putUpdates },
        userId
      );
    } else if (postStatus && postStatus >= 400) {
      // POST updates require a retry.
      await retry(properties, queue, actions, { ...postUpdates }, userId);
    } else if (putStatus && putStatus >= 400) {
      // PUT updates require a retry.
      await retry(properties, queue, actions, { ...putUpdates }, userId);
    } else {
      toast.error("Unable to sync trap data, please try again later.", {
        toastId: 5,
      });
    }
  };

  const syncOffline = async ({
    queue,
    auth,
    properties,
    actions,
    setSyncConflictModalState,
    isOnline,
  }: SyncOfflineParams) => {
    const { postUpdates, putUpdates } = queue;
    if (isOnline === true && (!isEmpty(postUpdates) || !isEmpty(putUpdates))) {
      // Get latest data before sync.
      await preSyncFetch(
        auth,
        queue,
        properties,
        {
          ...actions,
        },
        isOnline
      );
      actions
        .SyncOfflineUpdates(auth, queue, map(postUpdates), map(putUpdates))
        .catch(async (err: SyncError) => {
          logTraplogApiError(err as SyncError, isOnline);
          const postError = err.postError;
          const putError = err.putError;
          const postStatus =
            postError instanceof TraplogApiError
              ? postError?.data.response?.status || 400
              : undefined;
          const putStatus =
            putError instanceof TraplogApiError
              ? putError?.data.response?.status || 400
              : undefined;
          // Handle any sync conflicts.
          handleSyncConflicts(
            postUpdates,
            postError,
            postStatus,
            setSyncConflictModalState
          );
          // Handle sync retry for sync failures.
          await handleSyncRetry(
            properties,
            queue,
            actions,
            postUpdates,
            putUpdates,
            postStatus,
            putStatus,
            auth.id
          );
        });
    }
  };

  return syncOffline;
};

