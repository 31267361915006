import { AxiosError } from "axios";

export class LoginError extends Error {
  name: string;
  message: string;
  data: unknown;
  constructor(message: string, data: unknown) {
    super(message);
    this.name = "LoginError";
    this.message = message;
    this.data = data;
  }
}

export class TraplogApiError extends Error {
  url: string;
  data: AxiosError;
  constructor(url: string, message: string, data: AxiosError) {
    super(message);
    this.url = url;
    this.data = data;
  }
}

export class SyncError extends Error {
  postError?: Error | TraplogApiError;
  putError?: Error | TraplogApiError;
  constructor(
    postError?: Error | TraplogApiError,
    putError?: Error | TraplogApiError
  ) {
    super("Sync error(s) occured.");
    this.postError = postError;
    this.putError = putError;
  }
}
