import { isEqual } from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { compose } from "redux";
import { GrowerLogin } from "../../actions/auth/Auth";
import { getTenantLoginOptions } from "../../helpers/auth";
import { nzapTheme } from "../../helpers/themes";
import { updateTenantTheme } from "../../helpers/util";
import { useGetContext } from "../../hooks/useGetContext";
import "../../styles/GrowerLoginPage.scss";
import ToastButton from "../common/ToastButton";
import Footer from "./Footer";
import GrowerLoginLayout from "./GrowerLoginLayout";
import InvalidTenantPage from "./InvalidTenantPage";

const GrowerLoginPage: React.FC<IGrowerLoginPageProps> = (
  props: IGrowerLoginPageProps
) => {
  const history = useHistory();
  const params = useParams<any>();
  const { theme } = useGetContext();

  const tenantName = params.tenant;
  const validTenants = props.auth.validTenants;
  const isValidTenant = validTenants.includes(tenantName);

  useEffect(() => {
    // If the current theme is not equal to the theme to set.
    if (!isEqual(theme?.theme, nzapTheme)) {
      updateTenantTheme(theme, nzapTheme, tenantName);
    }
  }, [theme, tenantName]);

  useEffect(() => {
    if (props.auth.isAuthenticated && !props.auth.loading) {
      history.push("/");
    }
  }, [history, props.auth]);

  const doLogin = async () => {
    try {
      const loginOptions = getTenantLoginOptions(tenantName);
      await props.GrowerLogin(loginOptions);
    } catch (err) {
      toast.error(
        (err as Error).message || (
          <p className="p-0 m-0">
            An unknown error has occurred. If this happens again please contact
            support. <b>(Error code: TA1030)</b>
          </p>
        ),
        {
          toastId: 1,
          autoClose: false,
          closeButton: <ToastButton label="Dismiss" />,
        }
      );
    }
  };

  if (isValidTenant) {
    return (
      <GrowerLoginLayout tenant={tenantName}>
        <>
          <Row className="mb-3">
            <Col xs={12}>
              <h1>Trappa</h1>
            </Col>
          </Row>
          <Row style={{ width: "80%" }}>
            <Col xs={12}>
              <h5 className="text-center font-weight-light">
                Codling Moth Sensitive Market Trap Monitoring App
              </h5>
            </Col>
          </Row>
          <Row className="login-container">
            <Col xs={12}>
              <Button
                className={`login-btn-${tenantName}`}
                onClick={doLogin}
                disabled={props.auth.loading}
              >
                {props.auth.loading ? "Authenticating..." : "Start Monitoring"}
              </Button>
            </Col>
          </Row>
          <Footer tenantName={tenantName} />
        </>
      </GrowerLoginLayout>
    );
  }

  return (
    <GrowerLoginLayout>
      <InvalidTenantPage />
    </GrowerLoginLayout>
  );
};

const mapStateToProps = ({ auth }: { auth: IAuthReducer }) => {
	return { auth };
};

export default compose<React.ComponentType<Partial<IGrowerLoginPageProps>>>(
	connect(mapStateToProps, { GrowerLogin })
)(GrowerLoginPage);
