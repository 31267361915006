import { map } from "lodash";
import React, { useState } from "react";
import Icon from "react-icons-kit";
import {
  arrowRight,
  chevronDown,
  chevronUp,
  uploadCloud,
} from "react-icons-kit/feather";
import {
  Button,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
} from "reactstrap";
import { translateStatus } from "../../helpers/status";
import "../../styles/List.scss";
import Triangle from "../common/Triangle";

const CollapsibleList: React.FC<CollapsibleListProps> = (
  props: CollapsibleListProps
) => {
  const [open, setOpen] = useState<boolean>(true);

  const getStatus = (
    value: ITrapStatus | undefined
  ): IListItemStatus | null => {
    if (!value) return null;
    return translateStatus(value);
  };

  const renderItem = (item: ITrapListItem, index: number) => {
    const status = getStatus(item.status);
    const data = item.data as ITrap;
    const color = data ? data.lure?.display_color : null;
    return (
      <ListGroupItem
        className="justify-content-between trap-list-item"
        key={`list-item-${index}`}
      >
        <Row className="collapse-list-item">
          <Col xs={2}>
            <Triangle color={color ?? "#ccc"} size={36} />
          </Col>
          <Col className="pr-0" xs={7}>
            <ListGroupItemHeading className="no-margin">
              <strong>{item.title}</strong>
              {data.update_pending ? (
                <div className="no-margin sync-pending">
                  <Icon icon={uploadCloud} size={18} />
                </div>
              ) : (
                false
              )}
            </ListGroupItemHeading>
            <ListGroupItemText className="no-margin">
              {item.description}
            </ListGroupItemText>
            <ListGroupItemText className="no-margin">
              {item.location}
            </ListGroupItemText>
            <ListGroupItemText className={`no-margin text-${status?.color}`}>
              <strong>{status?.label}</strong>
            </ListGroupItemText>
          </Col>
          <Col className="text-right" xs={3}>
            <Button
              onClick={() => props.onItemClick(item)}
              outline
              color={status ? status.color : "default"}
            >
              <Icon icon={arrowRight} style={{ marginBottom: "2px" }} />
            </Button>
          </Col>
        </Row>
      </ListGroupItem>
    );
  };
  return (
    <div>
      <ListGroup>
        <ListGroupItem
          className="justify-content-between"
          onClick={() => setOpen(!open)}
        >
          <Row>
            {props.icon && <Icon icon={props.icon} size={32} />}
            <Col>
              <strong>{props.title}</strong>
            </Col>
            {props.subTitle && (
              <Col className="text-center">
                <strong>{props.subTitle}</strong>
              </Col>
            )}

            <Col className="text-right">
              {props.collapse && <Icon icon={open ? chevronUp : chevronDown} />}
            </Col>
          </Row>
        </ListGroupItem>
        <Collapse isOpen={props.collapse ? open : true}>
          {map(props.items, renderItem)}
        </Collapse>
      </ListGroup>
    </div>
  );
};

export default CollapsibleList;
