
import AWS from "aws-sdk";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import App from "./components/App";
import UpdateAppPrompt from "./components/UpdateAppPrompt";
import "./styles/index.scss";

// Initialize Google Analytics when in production.
if (
  process.env.NODE_ENV === "production" ||
  process.env.REACT_APP_ENVIRONMENT === "production"
) {
  const TRACKING_ID = "G-ELJ3S8LQ2J";
  ReactGA.initialize(TRACKING_ID);
}

AWS.config.update({
  region: "ap-southeast-2",
  apiVersion: "latest",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
  },
});

ReactDOM.render(
  <>
    <App />
    <UpdateAppPrompt />
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
