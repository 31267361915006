import { Route } from "react-router-dom";
import React, { useEffect } from "react";
import RequireAuth from "./RequireAuth";
import { UpdateTrapCache } from "../actions/traps/Traps";
import { connect } from "react-redux";

const RouteWithLayout: React.FC<IRouteWithLayoutProps> = (props) => {
  const { layout, component, requiresAuth, ...rest } = props;
  useEffect(() => {
    const updates: { [key:number]: ITrapPost } = { ...props.queue.postUpdates, ...props.queue.putUpdates };
    props.UpdateTrapCache(updates);
  }, [props])
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (requiresAuth) {
          return (
            <RequireAuth>
              {React.createElement(
                layout,
                {...props, ...rest},
                React.createElement(component, props)
              )}
            </RequireAuth>
          );
        }
        return React.createElement(
          layout,
          {...props, ...rest},
          React.createElement(component, props)
        );
      }}
    />
  );
};

const mapStateToProps = ({ queue }: { queue: IQueueReducer }) => {
  return { queue };
}

export default connect(mapStateToProps, { UpdateTrapCache })(RouteWithLayout);
