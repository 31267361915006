import { Dispatch } from "redux";
import { UI_LOG_SCROLL } from "./ActionTypes";

export const PersistScroll = (page: string, scrollTop: number) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UI_LOG_SCROLL,
      payload: { page, scrollTop },
    });
  };
};
