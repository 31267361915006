import React from "react";

const Triangle: React.FC<{ color: string; size: number }> = ({
  color,
  size,
}) => {
  return (
    <svg
      className="triangle"
      style={{
        width: size + 6,
        height: size + 6,
      }}
    >
      <polygon
        fill="none"
        stroke={color}
        strokeWidth={3}
        points={`${(size + 6) / 2} 10, ${size} ${size}, 6 ${size}`}
      />
    </svg>
  );
};

export default Triangle;
