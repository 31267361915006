import axios from "axios";
import { isBoolean, isNumber, isString } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { GetAllLures } from "../../actions/lures/Lures";
import {
  GetPropertyGroup,
  SelectProperty,
  SelectTrap,
  SelectTrapZone,
} from "../../actions/properties/Properties";
import {
  AddTrapMonitorRecord,
  GetAllTrapStatuses,
  GetTrapInfo,
  UpdateTrapCache,
} from "../../actions/traps/Traps";
import { logTraplogApiError } from "../../helpers/logging";
import { formatTenantName } from "../../helpers/util";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import "../../styles/TrapPage.scss";
import { LoginType } from "../../types/auth";
import LimitedTextArea from "../common/LimitedTextArea";
import Triangle from "../common/Triangle";

const TrapPage: React.FC<TrapPageProps> = (props: TrapPageProps) => {
  const params = useParams<{
    id: string;
    property: string;
    zone: string;
    trap: string;
  }>();
  const isOnline = useOnlineStatus();
  const [lure, setLure] = useState<ILure>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const today = moment();
  const activeTrap = props.properties.activeTrap;
  const trapUpdated = moment(activeTrap?.last_monitored_date);
  const trapWasUpdatedToday = trapUpdated.isSame(today, "day");
  const updateTrap = () => {
    const trap: ITrapPost = {
      id: trapWasUpdatedToday
        ? props.properties.activeTrap?.update_id
          ? Number(props.properties.activeTrap?.update_id)
          : props.properties.updates?.[today.format("YYYY-MM-DD")]?.[
              Number(params.trap)
            ] || null
        : props.properties.activeTrap?.id || null,
      update_id: props.properties.activeTrap?.update_id
        ? Number(props.properties.activeTrap?.update_id)
        : null,
      method:
        trapWasUpdatedToday &&
        activeTrap &&
        !props.queue?.postUpdates[activeTrap.id]
          ? "PUT"
          : "POST",
      visited_date: moment().format("YYYY-MM-DD"), // "2020-01-01"
      amount: props.properties.activeTrap?.amount || 0,
      lure_changed: props.properties.activeTrap?.lure_changed || false,
      base_changed: props.properties.activeTrap?.base_changed || false,
      operator:
        props.auth.loginType === LoginType.Grower
          ? `${formatTenantName(props.auth.tenantName)} Monitorer`
          : "",
      comments: props.properties.activeTrap?.comments || "",
      visited_at: moment().format("YYYY-MM-DD HH:mm:ss"), // "2020-01-01 12:00:00",
      location: {
        latitude: 0,
        longitude: 0,
      },
      trap_id: Number(params.trap),
    };
    return trap;
  };
  const [form, setForm] = useState<ITrapPost>(updateTrap());
  const operator = form.operator;

  // Fetches property group properties from the API
  useEffect(() => {
    let isMounted = true;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetch = async () => {
      try {
        setLoading(true);
        await props.GetAllTrapStatuses(props.auth, params.id);
        await props.GetPropertyGroup(props.auth, params.id);
        await props.GetAllLures(props.auth);
        await props.GetTrapInfo(
          props.auth,
          props.queue,
          params.trap,
          source.token
        );
      } catch (err) {
        logTraplogApiError(err as Error, isOnline);
        if (isOnline) {
          toast.error("Failed to fetch data. Click 'Cancel' and try again.");
        }
      } finally {
        await props.UpdateTrapCache();
        if (isMounted) setLoading(false);
      }
    };
    if (isMounted) fetch();
    return () => {
      // Cancel Active trap info fetch
      source.cancel("CANCEL");
      isMounted = false;
    };
    // We want this to run on mount and not auto-inject dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  useEffect(() => {
    const updatedTrap = updateTrap();
    setForm(updatedTrap);
    // We want this to run on mount and not auto-inject dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.properties.activeTrap]);

  useEffect(() => {
    if (props.auth.isPopulated && operator.trim() === "") {
      setForm((prevState) => ({
        ...prevState,
        operator: props.auth.name || "",
      }));
    }
  }, [props.auth, operator]);

  // When the properties are initially fetched,
  // update the selected one using the URL params and select the right trap

  useEffect(() => {
    // First load
    const selectedProperty = props.properties.properties[params.property];
    if (selectedProperty) {
      props.SelectProperty(selectedProperty);
    }
    const selectedTrap = props.properties.traps[params.trap];
    if (!selectedTrap) return;
    props.SelectTrap(selectedTrap);
    const lureId = selectedTrap.trap_lure_id;
    const lure = props.properties.lures[lureId];
    setLure(lure);
    // We want this to run on mount and not auto-inject dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = (): boolean => {
    const idValid = form.method === "PUT" ? isNumber(form.id) : true;
    const amountValid = !isNaN(Number(form.amount));
    const lureChangedValid = isBoolean(form.lure_changed);
    const baseChangedValid = isBoolean(form.base_changed);
    const operatorValid =
      isString(form.operator) && form.operator.trim() !== "";
    const commentsValid = isString(form.comments); // can be empty
    const trapIdValid = isNumber(form.trap_id);
    return (
      idValid &&
      amountValid &&
      lureChangedValid &&
      baseChangedValid &&
      operatorValid &&
      commentsValid &&
      trapIdValid
    );
  };

  const geoSuccess = useCallback((position: GeolocationPosition) => {
    setForm((f) => ({
      ...f,
      location_accuracy: position.coords.accuracy || null,
      location: {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },
    }));
  }, []);

  const geoError = (err: GeolocationPositionError) => {
    console.error(err);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
  }, [geoSuccess]);

  if (!props.properties || !props.properties.activeTrap) {
    return <div className="fill flex top trap">Loading</div>;
  }

  const {
    properties: { activeTrap: trap, activeProperty: property },
  } = props;

  const renderInstruction = (
    date: string,
    instruction: string,
    changed: boolean
  ) => {
    const now = moment();
    const changeDue = moment(date);
    if (changeDue.isAfter(now) || changed) return;
    return (
      <Col>
        <div className="trap-instruction">
          <svg width="33px" height="39px" viewBox="0 0 39 33">
            <path
              fill="#FFF500"
              stroke="black"
              strokeWidth={3}
              d="M3.5,30.8L19.5,3l16,27.8H3.5z"
            />
            <path
              d="M20.5,22.4h-2l-0.3-8.4h2.6L20.5,22.4z M19.5,23.7c0.4,0,0.7,0.1,1,0.4c0.2,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9
              c-0.2,0.2-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.2-0.2-0.4-0.5-0.4-0.9s0.1-0.7,0.4-0.9C18.8,23.9,19.1,23.7,19.5,23.7z"
            />
          </svg>
          <p>{instruction}</p>
        </div>
      </Col>
    );
  };

  const updateForm = (field: string, value: number | string | boolean) => {
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    setForm({
      ...form,
      [field]: value,
    });
  };

  const submit = async () => {
    try {
      // Update form amount to actually use number
      const today = moment().format("YYYY-MM-DD");
      const updateId =
        props.properties.updates?.[today]?.[form.trap_id] || null;
      await props.AddTrapMonitorRecord(props.auth, form, updateId);
      toast.success("Successfully updated trap.", {
        toastId: 1,
      });
      setTimeout(goBack, 0);
    } catch (err) {
      logTraplogApiError(err as Error, isOnline);
      toast.error((err as Error).message, {
        toastId: 2,
      });
      setTimeout(goBack, 0);
    }
  };

  const goBack = () => {
    (history as any).goBack();
  };

  const getUserUpdateString = () => {
    if (
      !trap.operator ||
      trap.operator.trim() === "" ||
      loading ||
      !trap.updated_at
    )
      return false;
    const updated = moment(trap.updated_at);
    const updatedAt = updated.format("hh:mma");
    const updatedBy = trap.operator === props.auth.name ? "you" : trap.operator;
    const string = `This trap was updated by ${updatedBy} at ${updatedAt}`;
    return string;
  };

  const getUpdateValidationString = () => {
    if (loading || validateForm()) return false;
    if (!trapWasUpdatedToday) return false;
    if (form.method === "PUT" && !isNumber(form.id)) {
      // No valid update id
      return `This trap was updated by someone else today, but we were unable to determine its identifier because of a connection issue. To update this trap you will need to go online first.`;
    }
  };

  const insectGroup =
    lure && lure.insects && lure.insects[trap.trap_lure_id]
      ? lure.insects[trap.trap_lure_id].insect_group
      : null;
  return (
    <div className="fill flex top trap mt-3" style={{ overflowX: "hidden" }}>
      <Row>
        <Col xs={12}>
          <div className="trap-title">
            <Triangle color={lure?.display_color ?? "#ccc"} size={32} />
            <h3 className="no-margin">{trap.name}</h3>
          </div>
          {loading ? (
            <div className="loader-inline">
              {/* <BarLoader height={4} color={theme?.theme.tenantColour} /> */}
              <Spinner />
            </div>
          ) : (
            <p
              className={`no-margin ${
                trap.status.due ? "text-danger" : "text-success"
              }`}
            >
              <strong>
                {trap.status.due ? "Due" : trap.status.done ? "Done" : null}
              </strong>
            </p>
          )}
        </Col>
        <Col xs={12}>
          <p className="no-margin">{insectGroup ? insectGroup.name : ""}</p>
          <p className="no-margin">{trap.location_description}</p>
          <p className="no-margin">{property?.name}</p>
        </Col>
        {renderInstruction(
          trap.lure_change_due,
          "Replace Cap",
          form.lure_changed
        )}
        {renderInstruction(
          trap.base_change_due,
          "Replace Base",
          form.base_changed
        )}
        <div className="spacer" />
        <Col className="mt-1" xs={12}>
          <FormGroup row>
            <Label for="moth-count" className="label" xs={6}>
              Moth Count
            </Label>
            <Col xs={6}>
              <Input
                disabled={loading}
                type="number"
                name="moth-count"
                value={form.amount}
                inputMode="numeric"
                min={0}
                onChange={(e) => {
                  updateForm("amount", e.target.value);
                }}
                onBlur={() => {
                  setForm({
                    ...form,
                    amount: Number(form.amount),
                  });
                }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <Row className="switch">
            <Label xs={8} className="label">
              Did you replace the cap?
            </Label>
            <Col xs={4} className="switch-toggle">
              <Switch
                disabled={loading}
                onChange={(checked) => updateForm("lure_changed", checked)}
                checked={form.lure_changed}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row className="switch">
            <Label xs={8} className="label">
              Did you replace the base?
            </Label>
            <Col xs={4} className="switch-toggle">
              <Switch
                disabled={loading}
                onChange={(checked) => updateForm("base_changed", checked)}
                checked={form.base_changed}
              />
            </Col>
          </Row>
        </Col>
        <div className="spacer" />
        <Col xs={12} className="notes">
          <Label>Notes</Label>
          <LimitedTextArea
            disabled={loading}
            limit={255}
            rows={5}
            value={form.comments ?? ""}
            onChange={(text) => updateForm("comments", text)}
          />
        </Col>
        <Col xs={6} className="actions">
          <Button block size="lg" color="secondary" onClick={goBack}>
            Cancel
          </Button>
        </Col>
        <Col xs={6} className="actions">
          <Button
            block
            size="lg"
            color="primary"
            onClick={submit}
            disabled={!validateForm() || loading}
          >
            Save
          </Button>
        </Col>
        <Col xs={12}>
          <small className="text-muted trap-messages">
            <br />
            {trap.status.done ? getUserUpdateString() : false}
            {getUpdateValidationString()}
          </small>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  auth,
  run,
  properties,
  queue,
}: {
  auth: IAuthReducer;
  run: IRunReducer;
  properties: IPropertyReducer;
  queue: IQueueReducer;
}) => {
  return { run, properties, auth, queue };
};
export default connect(mapStateToProps, {
  GetPropertyGroup,
  GetAllLures,
  SelectProperty,
  SelectTrapZone,
  SelectTrap,
  AddTrapMonitorRecord,
  GetAllTrapStatuses,
  UpdateTrapCache,
  GetTrapInfo,
})(TrapPage);
