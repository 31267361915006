import { IThemeContext, Theme } from "../contexts/theme";

export const sleep = (ms: number): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const hardReload = (reloadAfterClear = true) => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach(async (name) => {
        await caches.delete(name);
      });
    });
    if (reloadAfterClear) window.location.reload();
  }
}

export const formatTenantName = (name: string) => {
  if (name === 'nzap') {
    return name.toLocaleUpperCase();
  }

  return name;
}

export const updateTenantTheme = (
  themeContext: IThemeContext | null,
  theme: Theme | {},
  tenantName: string
) => {
  if (tenantName === 'nzap') {
    if (Object.keys(theme).length !== 0) {
      themeContext?.update(theme);
    }
  } else {
    themeContext?.clear();
  }
}

export const kibanaUrlHost = (
  process.env.REACT_APP_KIBANA_URL as string
).substring(8);

export const DEV_MODE = process.env.NODE_ENV === "development";
