import { UI_LOG_SCROLL } from "../../actions/ui/ActionTypes";

const generateInitialState = (): IUIReducer => {
  const state: IUIReducer = {
    scrollTop: {},
  };
  return state;
};

const INIT = generateInitialState();

export default (state = INIT, action: any): IUIReducer => {
  switch (action.type) {
    case UI_LOG_SCROLL:
      return {
        ...state,
        scrollTop: {
          ...state.scrollTop,
          [action.payload.page]: action.payload.scrollTop,
        },
      };

    default:
      return state;
  }
};
