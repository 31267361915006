import { intersection } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { compose } from "redux";
import { GetUser } from "../actions/auth/Auth";
import { GetAllLures } from "../actions/lures/Lures";
import { GetPropertyGroup } from "../actions/properties/Properties";
import {
  ClearSyncQueue,
  GetAllTrapStatuses,
  GetTrapInfo,
  SyncOfflineUpdates,
  SyncRetry,
  UpdateTrapCache,
} from "../actions/traps/Traps";
import LoadingPage from "../components/common/LoadingPage";
import SyncConflictModal, {
  ModalState,
} from "../components/common/SyncConflictModal";
import { LoginError } from "../helpers/errors";
import { useOnlineStatus } from "../hooks/useOnlineStatus";
import { useSync } from "../hooks/useSync";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
const RequireAuth: React.FC<IRequireAuthProps> = (props) => {
  const { children, auth, queue, ...rest } = props;
  const history = useHistory();
  const isOnline = useOnlineStatus();
  const [syncConflictModalState, setSyncConflictModalState] = useState({
    open: false,
    conflictingRecords: [],
    remainingRecords: { postUpdates: {}, putUpdates: {} },
  } as ModalState);
  const syncOffline = useSync();

  useEffect(() => {
    if (!auth.loading && auth.isAuthenticated && !auth.isPopulated) {
      props.GetUser().catch((error: Error | LoginError) => {
        toast.error(error.message);
      });
    }
  }, [auth, props]);

  useEffect(() => {
    syncOffline({
      queue: props.queue,
      auth: props.auth,
      properties: props.properties,
      actions: {
        GetAllTrapStatuses: props.GetAllTrapStatuses,
        GetPropertyGroup: props.GetPropertyGroup,
        GetAllLures: props.GetAllLures,
        GetTrapInfo: props.GetTrapInfo,
        UpdateTrapCache: props.UpdateTrapCache,
        SyncOfflineUpdates: props.SyncOfflineUpdates,
        ClearSyncQueue: props.ClearSyncQueue,
        SyncRetry: props.SyncRetry,
      },
      setSyncConflictModalState,
      isOnline,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  return (
    <Route
      {...rest}
      render={() => {
        if (!auth.isAuthenticated && auth.loading) {
          return (
            <>
              <SyncConflictModal
                modalState={[syncConflictModalState, setSyncConflictModalState]}
              />
              <LoadingPage />
            </>
          );
        }
        if (auth.isAuthenticated) {
          return (
            <>
              <SyncConflictModal
                modalState={[syncConflictModalState, setSyncConflictModalState]}
              />
              {children}
            </>
          );
        }
        // Redirect to the grower login page if the domain
        // contains a valid tenant.
        const domains = window.location.hostname.split(".");
        const validTenants = auth.validTenants;
        const intersec = intersection(domains, validTenants);
        if (intersec.length > 0) {
          const tenantName = intersec[0];
          const growerLoginURI = `/${tenantName}/grower-login`;
          if (history.location.pathname !== growerLoginURI)
            history.push(growerLoginURI);
          else history.push("/login");
        } else {
          history.push("/login");
        }
      }}
    />
  );
};

const mapStateToProps = ({
  auth,
  queue,
  properties,
}: {
  auth: IAuthReducer;
  queue: IQueueReducer;
  properties: IPropertyReducer;
}) => {
  return { auth, queue, properties };
};

export default compose<React.ComponentType<Partial<IRequireAuthProps>>>(
  connect(mapStateToProps, {
    GetUser,
    SyncOfflineUpdates,
    GetAllTrapStatuses,
    GetPropertyGroup,
    GetAllLures,
    GetTrapInfo,
    UpdateTrapCache,
    ClearSyncQueue,
    SyncRetry,
  })
)(RequireAuth);
