import { Theme } from "../contexts/theme";

export const nzapTheme: Theme = {
	tenantColour: '#4e5b6a',
	tenantDarkerColour: '#3a4e63',
}

export const defaultTheme: Theme = {
	tenantColour: '',
	tenantDarkerColour: '',
}

export const emptyTheme: Theme = {
	tenantColour: '',
	tenantDarkerColour: '',
}