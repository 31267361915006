import axios, { AxiosRequestConfig, Method } from "axios";
import { toast } from "react-toastify";
import { ActionCreator } from "redux";
import { SEASONAL_DB } from "../config";
import { logTraplogApiError } from "./logging";

export const buildRequest = (
  method: Method,
  url: string,
  data?: unknown,
  headers?: { [key: string]: unknown }
) => {
  const requestHeaders: { [key: string]: unknown } = {};
  if (!headers || !("seasonalDatabase" in headers)) {
    requestHeaders.seasonalDatabase = SEASONAL_DB;
  }
  const config: AxiosRequestConfig = {
    method,
    url,
    responseType: "json",
    headers: requestHeaders,
  };
  if (data) {
    config.data = data;
  }
  // If accesstoken, include it
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (headers) {
    config.headers = { ...config.headers, ...headers };
  }
  return config;
};

/**
 * Fetches data intended before the sync begins. This
 * ensures the local state is up-to-date to avoid
 * conflicts.
 *
 * @param auth
 * @param queue
 * @param properties
 * @param actions
 * @param isOnline
 */
export const preSyncFetch = async (
  auth: IAuthReducer,
  queue: IQueueReducer,
  properties: IPropertyReducer,
  actions: {
    GetAllTrapStatuses: ActionCreator<any>;
    GetPropertyGroup: ActionCreator<any>;
    GetAllLures: ActionCreator<any>;
    GetTrapInfo: ActionCreator<any>;
    UpdateTrapCache: ActionCreator<any>;
  },
  isOnline: boolean
) => {
  // Get latest data before sync.
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  try {
    await actions.GetAllTrapStatuses(
      auth,
      properties.activeProperty?.pivot.property_group_id
    );
    await actions.GetPropertyGroup(
      auth,
      properties.activeProperty?.pivot.property_group_id
    );
    await actions.GetAllLures(auth);
    await actions.GetTrapInfo(
      auth,
      queue,
      properties.activeTrap?.id,
      source.token
    );
  } catch (err) {
    logTraplogApiError(err as Error, isOnline);
    toast.error("Unable to sync, failed to prepare sync. Please try again.");
  } finally {
    await actions.UpdateTrapCache();
  }
};
