import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { useGetContext } from "../../hooks/useGetContext";

interface Props {
	onClick: () => void
}

const InstallPWAButton = ({ onClick }: Props) => {
	const { theme } = useGetContext();
	const history = useHistory();
	const [supportsPWA, setSupportsPWA] = useState(false);
	const [promptInstall, setPromptInstall] = useState<any>(null);

	useEffect(() => {
		const handler = (e: any) => {
			e.preventDefault();
			setSupportsPWA(true);
			setPromptInstall(e);
		};
		window.addEventListener("beforeinstallprompt", handler);

		return () => window.removeEventListener("transitionend", handler);
	}, []);

	const onClickInstall = (evt: any) => {
		evt.preventDefault();
		if (!promptInstall) {
			return;
		}
		promptInstall.prompt();
	};

	const onClickInstructions = async () => {
		history.push('/how-to-install');
	};

	return (
		<Button
			className="primary-btn run-number-btn"
			style={{ background: theme?.theme.tenantDarkerColour }}
			title="Install app"
			onClick={(e) => {
				if (supportsPWA) {
					onClickInstall(e);
				} else {
					onClickInstructions();
				}
				onClick();
			}}
		>
			Install
		</Button>
	);
};

export default InstallPWAButton;