import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";
import { BASE_URL, SEASONAL_DB } from "../../config";
import { buildRequest } from "../../helpers/axios";
import { TraplogApiError } from "../../helpers/errors";
import { LoginType } from "../../types/auth";
import {
  PROPERTYGROUP_FETCH_ERROR,
  PROPERTYGROUP_FETCH_IN_PROGRESS,
  PROPERTYGROUP_FETCH_SUCCESS,
  SELECT_PROPERTY,
  SELECT_TRAP,
  SELECT_TRAP_ZONE,
} from "./ActionTypes";

export const GetPropertyGroup = async (auth: IAuthReducer, group: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PROPERTYGROUP_FETCH_IN_PROGRESS,
    });
    const url = `${BASE_URL}/property-groups/${group}?with[]=properties.trappingZones.traps`;
    try {
      const headers =
        auth.loginType === LoginType.Credentials
          ? { seasonalDatabase: SEASONAL_DB }
          : { seasonalDatabase: auth.seasonalDatabase };
      const opts = buildRequest("GET", url, null, headers);
      const result = await axios(opts);
      if (result instanceof Error) throw result;
      if (!result.data) throw new Error("Invalid response from server");
      dispatch({
        type: PROPERTYGROUP_FETCH_SUCCESS,
        payload: result.data.properties as IProperty[],
      });
    } catch (err) {
      dispatch({
        type: PROPERTYGROUP_FETCH_ERROR,
        payload: err,
      });
      throw new TraplogApiError(
        url,
        "Could not get property group",
        err as AxiosError
      );
    }
  };
};

export const SelectProperty = (property: IProperty) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SELECT_PROPERTY,
      payload: property,
    });
  };
};

export const SelectTrap = (trap: ITrap) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SELECT_TRAP,
      payload: trap,
    });
  };
};

export const SelectTrapZone = (zone: ITrappingZone) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SELECT_TRAP_ZONE,
      payload: zone,
    });
  };
};
