import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import "../../styles/InstallInstructionsPage.scss";
import { Device } from "./typings";

const InstallInstructionsPage = () => {
	const [device, setDevice] = useState<Device | null>();

	return (
		<div className="fill flex install-instructions-page">
			<Row className="w-100" >
				<Col><h3>How to Install</h3></Col>
			</Row>
			<Row className="w-100 mb-3">
				<Col xs={5}><b>Choose Device:</b></Col>
				<Col>
					<FormGroup check>
						<Row>
							<Col className="float-left">
								<Input
									style={{
										height: '1em',
										width: '1em',
										verticalAlign: 'middle',
									}}
									name="device"
									type="radio"
									onChange={(e) => setDevice(Device.Apple)}
								/>
								{' '}
								<Label check>
									Apple
								</Label>
							</Col>
							<Col className="float-left">
								<Input
									style={{
										height: '1em',
										width: '1em',
										verticalAlign: 'middle',
									}}
									name="device"
									type="radio"
									onChange={(e) => setDevice(Device.Android)}
								/>
								{' '}
								<Label check>
									Android
								</Label>
							</Col>
						</Row>
					</FormGroup>
				</Col>
			</Row>
			{device &&
				<Row className="w-100">
					<Col>
						{device === Device.Apple ? (
							<ul>
								<li>Open this app in Safari</li>
								<li>Click on the share button located at the center bottom of the screen</li>
								<li>Scroll to 'Add to Home Screen' button</li>
								<li>Click on 'Add to Home Screen' button</li>
								<li>Click on 'Add' button located top-right</li>
							</ul>
						) : (
								// <p>This app is installable through Google Chrome.</p>
								<ul>
									<li>Open this app in Google Chrome</li>
									<li>Click on the 'Install' button</li>
									<li>Click 'Add Trappa to Home screen'</li>
									<li>Click 'Add'</li>
									<li>Click 'Add automatically'</li>
								</ul>
						)}
					</Col>
				</Row>
			}
		</div>
	)
}

export default InstallInstructionsPage;