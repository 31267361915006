import '../styles/Wrapper.scss';
import { Col, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import React from 'react';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from '../components/common/Header';

const AppWithNavbar: React.FC<RouteComponentProps & IAppWithNavBarProps> = (props) => {
  const { children, hideBackButton } = props;
  return (
    <div style={{ overflow: "hidden" }} className="main">
      <Header hideBackButton={hideBackButton} />    
      <Row className="body">
        <Col xs="12">
          <div className="app-container">
            <div className="app-main">{children}</div>
          </div>
        </Col>
      </Row>
      <ToastContainer position="bottom-center" autoClose={5000} />
    </div>
  );
}

export default AppWithNavbar