import axios, { AxiosError, AxiosResponse, CancelToken } from "axios";
import { each, forEach, isEmpty, map } from "lodash";
import { Dispatch } from "redux";
import { BASE_URL, SEASONAL_DB } from "../../config";
import { buildRequest } from "../../helpers/axios";
import { SyncError, TraplogApiError } from "../../helpers/errors";
import { sleep } from "../../helpers/util";
import { MAX_SYNC_RETRY_ATTEMPTS } from "../../reducers/QueueReducer/QueueReducer";
import { LoginType } from "../../types/auth";
import {
  OFFLINE_SYNC_CANCEL,
  OFFLINE_SYNC_CLEAR,
  OFFLINE_SYNC_ERROR,
  OFFLINE_SYNC_IN_PROGRESS,
  OFFLINE_SYNC_REMOVE,
  OFFLINE_SYNC_RETRY_FAILURE,
  OFFLINE_SYNC_RETRY_INCREMENT,
  OFFLINE_SYNC_SUCCESS,
  TRAPS_GET_STATUS_ERROR,
  TRAPS_GET_STATUS_IN_PROGRESS,
  TRAPS_GET_STATUS_SUCCESS,
  TRAP_GET_INFO_ERROR,
  TRAP_GET_INFO_IN_PROGRESS,
  TRAP_GET_INFO_SUCCESS,
  TRAP_UPDATE_ERROR,
  TRAP_UPDATE_IN_PROGRESS,
  TRAP_UPDATE_PENDING,
  TRAP_UPDATE_SUCCESS,
} from "./ActionTypes";

export const PushTrapMonitorRecords = async (
  auth: IAuthReducer,
  traps: ITrapPost[],
  method: "PUT" | "POST"
): Promise<AxiosResponse<any>> => {
  const records = map(traps, (trap) => {
    trap.amount = Number(trap.amount) || 0;
    return trap;
  });
  const headers =
    auth.loginType === LoginType.Credentials
      ? { seasonalDatabase: SEASONAL_DB }
      : { seasonalDatabase: auth.seasonalDatabase };
  const opts = buildRequest(
    method,
    `${BASE_URL}/trap-monitoring-records`,
    { records },
    headers
  );
  const result = await axios(opts);
  return result;
};

export const SyncOfflineUpdates = async (
  auth: IAuthReducer,
  queue: IQueueReducer,
  postUpdates: ITrapPost[],
  putUpdates: ITrapPost[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: OFFLINE_SYNC_IN_PROGRESS,
    });
    if (queue.loading) {
      dispatch({
        type: OFFLINE_SYNC_CANCEL,
      });
      return;
    }
    const errors: {
      postError?: Error | TraplogApiError;
      putError?: Error | TraplogApiError;
    } = {};
    // Wait 1 seconds for network to come back online
    await sleep(1000);
    // POST updates.
    try {
      let postResult: any = null;
      if (!isEmpty(postUpdates)) {
        postResult = await PushTrapMonitorRecords(auth, postUpdates, "POST");
        if (postResult instanceof Error) throw postResult;
        if (!postResult || !postResult.data) {
          throw new Error("Invalid response from server");
        }
      }
      each(postUpdates, (trap, index) => {
        const updateId = postResult.data[index]
          ? postResult.data[index].id
          : null;
        dispatch({
          type: TRAP_UPDATE_SUCCESS,
          payload: {
            trap,
            updateId,
          },
        });
      });
    } catch (err) {
      dispatch({
        type: OFFLINE_SYNC_ERROR,
      });
      errors.postError = new TraplogApiError(
        `${BASE_URL}/trap-monitoring-records`,
        "Failed to sync",
        err as AxiosError
      );
    }
    // PUT updates.
    try {
      let putResult: any = null;
      if (!isEmpty(putUpdates)) {
        putResult = await PushTrapMonitorRecords(auth, putUpdates, "PUT");
        if (putResult instanceof Error) throw putResult;
        if (!putResult || !putResult.data) {
          throw new Error("Invalid response from server");
        }
      }
      each(putUpdates, (trap, index) => {
        const updateId = putResult.data[index]
          ? putResult.data[index].id
          : null;
        dispatch({
          type: TRAP_UPDATE_SUCCESS,
          payload: {
            trap,
            updateId,
          },
        });
      });
    } catch (err) {
      dispatch({
        type: OFFLINE_SYNC_ERROR,
      });
      errors.putError = new TraplogApiError(
        `${BASE_URL}/trap-monitoring-records`,
        "Failed to sync",
        err as AxiosError
      );
    }
    // No errors so success.
    if (!errors.postError && !errors.putError) {
      dispatch({
        type: OFFLINE_SYNC_SUCCESS,
      });
    }
    // Throw error(s) if there are errors to be thrown.
    if (errors.postError || errors.putError) {
      throw new SyncError(errors.postError, errors.putError);
    }
  };
};

export const AddTrapMonitorRecord = async (
  auth: IAuthReducer,
  trap: ITrapPost
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: TRAP_UPDATE_IN_PROGRESS,
    });
    try {
      if (trap.method === "PUT" && !trap.id) throw new Error("PUT_MISSING_ID");
      const result = await PushTrapMonitorRecords(auth, [trap], trap.method);
      if (!result.data) throw new Error("Invalid response from server");
      const updateId = result.data[0] ? result.data[0].id : null;
      dispatch({
        type: TRAP_UPDATE_SUCCESS,
        payload: {
          trap,
          updateId,
        },
      });
    } catch (err) {
      const error = err as AxiosError;
      if (
        error.message === "PUT_MISSING_ID" ||
        (error.response && error.response.status === 422)
      ) {
        throw new Error("Unable to update trap. Update ID is not present");
      }
      dispatch({
        type: TRAP_UPDATE_ERROR,
        payload: {
          error: err,
          update: trap,
        },
      });
      throw new TraplogApiError(
        `${BASE_URL}/trap-monitoring-records`,
        "Failed to update trap, data stored for later transmission",
        err as AxiosError
      );
    }
  };
};

export const AddManyTrapMonitorRecords = async (
  auth: IAuthReducer,
  traps: ITrapPost[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: TRAP_UPDATE_IN_PROGRESS,
    });
    forEach(traps, async (trap) => {
      try {
        if (trap.method === "PUT" && !trap.id)
          throw new Error("PUT_MISSING_ID");
        const result = await PushTrapMonitorRecords(auth, [trap], trap.method);
        if (!result.data) throw new Error("Invalid response from server");
        const updateId = result.data[0] ? result.data[0].id : null;
        dispatch({
          type: TRAP_UPDATE_SUCCESS,
          payload: {
            trap,
            updateId,
          },
        });
      } catch (err) {
        const error = err as AxiosError;
        if (
          error.message === "PUT_MISSING_ID" ||
          (error.response && error.response.status === 422)
        ) {
          throw new Error("Unable to update trap. Update ID is not present");
        }
        dispatch({
          type: TRAP_UPDATE_ERROR,
          payload: {
            error: err,
            update: trap,
          },
        });
        throw new TraplogApiError(
          `${BASE_URL}/trap-monitoring-records`,
          "Failed to update trap, data stored for later transmission",
          err as AxiosError
        );
      }
    });
  };
};

export const ClearSyncQueue = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: OFFLINE_SYNC_CLEAR,
    });
  };
};

export const UpdateTrapCache = (updates: { [key: number]: ITrapPost }) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: TRAP_UPDATE_PENDING,
      payload: updates,
    });
  };
};

export const GetAllTrapStatuses = async (
  auth: IAuthReducer,
  propertyGroup: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: TRAPS_GET_STATUS_IN_PROGRESS,
    });
    const url = `${BASE_URL}/property-groups/${propertyGroup}/trap-statuses`;
    try {
      const headers =
        auth.loginType === LoginType.Credentials
          ? { seasonalDatabase: SEASONAL_DB }
          : { seasonalDatabase: auth.seasonalDatabase };
      const opts = buildRequest("GET", url, null, headers);
      const result = await axios(opts);
      if (!result || !result.data)
        throw new Error("Invalid response from server");
      dispatch({
        type: TRAPS_GET_STATUS_SUCCESS,
        payload: {
          data: result.data,
          propertyGroup,
        },
      });
    } catch (err) {
      dispatch({
        type: TRAPS_GET_STATUS_ERROR,
        payload: err,
      });
      throw new TraplogApiError(
        url,
        "Failed to get trap statuses",
        err as AxiosError
      );
    }
  };
};

export const GetTrapInfo = async (
  auth: IAuthReducer,
  queue: IQueueReducer,
  trapId: string,
  cancelToken?: CancelToken
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: TRAP_GET_INFO_IN_PROGRESS,
    });
    const url = `${BASE_URL}/traps/${trapId}?append[]=LastMonitoredDate&append[]=LastMonitoringRecord`;
    try {
      const headers =
        auth.loginType === LoginType.Credentials
          ? { seasonalDatabase: SEASONAL_DB }
          : { seasonalDatabase: auth.seasonalDatabase };
      const opts = buildRequest("GET", url, null, headers);
      if (cancelToken) {
        opts.cancelToken = cancelToken;
      }
      const result: AxiosResponse = await axios(opts);
      if (result && (result as any).__CANCEL__ === true) return;
      if (!result || !result.data)
        throw new Error("Invalid response from server");
      dispatch({
        type: TRAP_GET_INFO_SUCCESS,
        payload: {
          trap: result.data,
          pendingUpdates: {
            postUpdates: { ...queue.postUpdates },
            putUpdates: { ...queue.putUpdates },
          },
        },
      });
    } catch (err) {
      dispatch({
        type: TRAP_GET_INFO_ERROR,
        payload: err,
      });
      throw new TraplogApiError(
        url,
        "Failed to get trap info",
        err as AxiosError
      );
    }
  };
};

export const RemoveUpdateFromQueue = (updateId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: OFFLINE_SYNC_REMOVE,
      payload: {
        updateId,
      },
    });
    dispatch({
      type: OFFLINE_SYNC_SUCCESS,
    });
  };
};

/**
 * Returns whether the sync retry limit has been reached.
 * If not, then the counter is incremented.
 *
 * @param queue Queue reducer.
 * @param updates: Object that maps traps IDs to a trap monitoring record for syncing.
 * @returns
 */
export const SyncRetry = (
  queue: IQueueReducer,
  updates: {
    [key: string]: ITrapPost;
  }
) => {
  return async (dispatch: Dispatch) => {
    const syncRetryCount = queue.syncRetryCounter + 1;
    if (syncRetryCount === MAX_SYNC_RETRY_ATTEMPTS) {
      dispatch({
        type: OFFLINE_SYNC_RETRY_FAILURE,
        payload: {
          trapIds: Object.keys(updates),
        },
      });
      return true;
    }
    dispatch({
      type: OFFLINE_SYNC_RETRY_INCREMENT,
    });
    return false;
  };
};
