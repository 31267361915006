import { TenantInfo } from "../types/auth";

const nzap: TenantInfo = {
	support: {
		email: 'info@applesandpears.nz',
		phone: '06 8737080'
	},
	pages: {
		runNumber: {
			supportAlert: {
				text: 'Passcodes are created in the NZAP Weather & Disease Portal under Trapping > Mobile Monitoring App.'
			}
		}
	}
}

export const getTenantInfo = (tenantName: string): TenantInfo | undefined => {
	switch (tenantName) {
		case 'nzap':
			return nzap
		default:
			break;
	}
}