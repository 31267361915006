import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { FindRun, ResetRun, UpdateRunNumber } from "../../actions/runs/Runs";
import { TraplogApiError } from "../../helpers/errors";
import { logTraplogApiError } from "../../helpers/logging";
import { useGetContext } from "../../hooks/useGetContext";
import useLocalStorageState from "../../hooks/useLocalStorageState";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import "../../styles/RunNumberPage.scss";
import { LoginType } from "../../types/auth";
import { PastRuns } from "../../types/run";
import RunNumberSelect from "../common/RunNumberSelect";
import Footer from "./Footer";

const RunNumberPage: React.FC<RunNumberPageProps> = (
  props: RunNumberPageProps
) => {
  const history = useHistory();
  const { theme } = useGetContext();
  const [formLoading, setFormLoading] = useState(false);
  const [pastRuns, setPastRuns] = useLocalStorageState<PastRuns>(
    "pastRuns",
    JSON.parse(localStorage.getItem("pastRuns") || "[]") as PastRuns
  );
  const isOnline = useOnlineStatus();

  const loginType = props.auth.loginType;

  useEffect(() => {
    // Reset run when we hit this page
    props.ResetRun();
    // We want this to run on mount and not auto-inject dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startRun = async (runNumber?: string) => {
    const number = runNumber || props.run.number;
    setFormLoading(true);
    try {
      const data = await props.FindRun(props.auth, number);
      const propertyGroup = data.property_group;
      const group = data?.property_group_id;
      if (!group) throw new Error(`Could not load run number ${number}`);
      if (!pastRuns.find((pr) => pr.passcode === data.passcode)) {
        setPastRuns([
          ...pastRuns,
          {
            passcode: data.passcode,
            name: propertyGroup.name,
            description: propertyGroup.description,
          },
        ]);
      }
      props.UpdateRunNumber(runNumber);
      setFormLoading(false);
      history.push(`/property-group/${group}`);
      return;
    } catch (err) {
      logTraplogApiError(err as Error, isOnline);
      if (err instanceof TraplogApiError) {
        const error = err.data;
        if (error.response && error.response.status) {
          const numberString =
            loginType === LoginType.Credentials ? "run number" : "passcode";
          switch (error.response.status) {
            case 403: {
              const errorMessage = `You don't have access to this ${numberString}`;
              toast.error(errorMessage, {
                toastId: 3,
              });
              break;
            }
            case 404: {
              const errorMessage =
                runNumber !== ""
                  ? `Unable to find ${numberString} ${runNumber}.\nPlease try again.`
                  : `Please enter a ${numberString}.`;
              toast.error(errorMessage, {
                toastId: 3,
              });
              break;
            }
            case 429:
              toast.error(
                `Too many attempts. Please wait a while and try again.`,
                {
                  toastId: 3,
                }
              );
              break;
            default:
              toast.error(
                <p className="p-0 m-0">
                  An unknown error has occurred. If this happens again please
                  contact support. <b>(Error code: TA1050)</b>
                </p>,
                {
                  toastId: 3,
                  autoClose: false,
                }
              );
              break;
          }
        } else if (error) {
          toast.error(error.message);
        }
      } else {
        toast.error(
          <p className="p-0 m-0">
            An unknown error has occurred. If this happens again please contact
            support. <b>(Error code: TA1060)</b>
          </p>,
          {
            autoClose: false,
          }
        );
      }
    }
    setFormLoading(false);
  };

  const onChangeSelect = (
    newValue: SingleValue<{ value: string; label: string }>
  ) => {
    const value = newValue?.value.trim();
    props.UpdateRunNumber(value || "");
  };

  return (
    <div className="fill flex center run-number-page">
      <Row className="w-100">
        <Col xs={12} className="w-100">
          <Label>
            <h3>
              {loginType === LoginType.Credentials ? "Run Number" : "Passcode"}
            </h3>
          </Label>
          <div style={{ width: "100%" }}>
            <RunNumberSelect
              pastRuns={pastRuns}
              setPastRuns={setPastRuns}
              onChange={onChangeSelect}
              loginType={loginType}
              loading={formLoading}
              onCreate={startRun}
            />
          </div>
          <Button
            className="primary-btn"
            style={{ background: theme?.theme.tenantColour }}
            onClick={() => startRun()}
            disabled={isEmpty(props.run.number) || formLoading}
          >
            {formLoading ? (
              <Spinner style={{ width: "1.5em", height: "1.5em" }} />
            ) : (
              "Start"
            )}
          </Button>
        </Col>
      </Row>
      <Row style={{ position: "absolute", bottom: 0, paddingInline: "8px" }}>
        <Col>
          <Footer tenantName={props.auth.tenantName} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  run,
  auth,
}: {
  run: IRunReducer;
  auth: IAuthReducer;
}) => {
  return { run, auth };
};

export default connect(mapStateToProps, { FindRun, UpdateRunNumber, ResetRun })(
  RunNumberPage
);
