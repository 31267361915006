import React, { useState } from "react";
import { emptyTheme } from "../helpers/themes";
import { IThemeContext, Theme, ThemeContext } from "./theme";

interface Props {
	children: JSX.Element | JSX.Element[];
}

const ContextProvider = ({ children }: Props) => {
	const themeLocalStorage = localStorage.getItem('theme');
	const initalThemeValue: Theme = themeLocalStorage ?
		JSON.parse(themeLocalStorage) : emptyTheme
	const [themeValue, setThemeValue] = useState<Theme>(initalThemeValue);

	const initialTheme: IThemeContext = {
		theme: themeValue,
		update(theme: Partial<Theme>) {
			const newTheme: Theme = { ...themeValue };
			if (theme.tenantColour) newTheme.tenantColour = theme.tenantColour;
			if (theme.tenantDarkerColour) newTheme.tenantDarkerColour = theme.tenantDarkerColour;
			setThemeValue(newTheme);
			localStorage.setItem('theme', JSON.stringify(newTheme));
		},
		clear() {
			localStorage.setItem('theme', JSON.stringify(emptyTheme));
			setThemeValue(emptyTheme)
		}
	}

	return (
		<ThemeContext.Provider value={initialTheme}>
			{children}
		</ThemeContext.Provider>
	)
}

export default ContextProvider;