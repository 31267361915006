import FuzzySearch from "fuzzy-search";
import { each, every, filter, includes, isEmpty, map, some } from "lodash";
import React, { useEffect, useState } from "react";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { GetAllLures } from "../../actions/lures/Lures";
import {
  GetPropertyGroup,
  SelectProperty,
} from "../../actions/properties/Properties";
import { GetAllTrapStatuses } from "../../actions/traps/Traps";
import { logTraplogApiError } from "../../helpers/logging";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import "../../styles/PropertiesPage.scss";
import LoadingPage from "../common/LoadingPage";
import CollapsibleList from "./CollapsibleList";

const PropertyGroupPage: React.FC<PropertyGroupPageProps> = (
  props: PropertyGroupPageProps
) => {
  const [properties, setProperties] = useState<IProperty[]>([]);
  const [propertyFilter, setPropertyFilter] = useState<string>("");
  const params = useParams<{ id: string }>();
  const [currentPropertyGroup, setCurrentPropertyGroup] = useState<string>("");
  const history = useHistory();
  const isOnline = useOnlineStatus();

  // Fetches property group properties from the API
  useEffect(() => {
    const fetch = async () => {
      try {
        await props.GetAllTrapStatuses(props.auth, params.id);
        await props.GetPropertyGroup(props.auth, params.id);
        await props.GetAllLures(props.auth);
      } catch (err) {
        logTraplogApiError(err as Error, isOnline);
        if (isOnline) {
          toast.error(
            "Failed to fetch orchards. Tap on the Trappa logo to refresh."
          );
        }
      }
    };
    if (
      currentPropertyGroup !== params.id ||
      (isEmpty(props.properties.properties) && !props.properties.loading)
    ) {
      setCurrentPropertyGroup(params.id);
      fetch();
    }
    // We want this to run on mount and not auto-inject dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, isOnline]);

  // Handles updates when filter changes
  useEffect(() => {
    // If empty, revert back to what we have in the reducer
    if (propertyFilter.trim() === "") {
      setProperties(map(props.properties.properties));
    }
    const searcher = new FuzzySearch(
      map(props.properties.properties),
      ["identifier", "name"],
      {
        caseSensitive: false,
      }
    );
    const result = searcher.search(propertyFilter);
    setProperties(result);
  }, [propertyFilter, props.properties.properties]);

  // When the properties are initially fetched,
  // update the local filterable list.
  useEffect(() => {
    if (
      isEmpty(properties) &&
      props.properties.properties &&
      !isEmpty(props.properties.properties)
    ) {
      setProperties(map(props.properties.properties));
    }
  }, [properties, props.properties.properties]);

  const selectProperty = (item: ICollapsibleListItem) => {
    props.SelectProperty(item.data);
    history.push(`/property-group/${params.id}/property/${item.id}`);
  };

  const renderProperties = () => {
    const items = map(properties, (property): ICollapsibleListItem => {
      // extract possible statuses from traps
      const zones = map(property.trapping_zones, (zone) => zone.id);

      const due: boolean[] = [];
      const done: boolean[] = [];
      const traps = filter(props.properties.traps, (trap) =>
        includes(zones, trap.trapping_zone_id)
      );
      each(traps, (trap) => {
        due.push(trap.status.due);
        done.push(trap.status.done);
      });
      const status = {
        due: some(due, (i) => i === true),
        done: every(done, (i) => i === true),
      };
      return {
        id: property.id,
        title: property.identifier,
        data: property,
        description: property.name,
        status,
      };
    });
    return (
      <CollapsibleList
        title="Orchard"
        subTitle="Status"
        collapse={false}
        items={items}
        onItemClick={selectProperty}
      />
    );
  };

  return (
    <div className="fill flex top properties" style={{ overflowX: "hidden" }}>
      <Row>
        <Col xs={12}>
          <Label>
            <h3>Orchards</h3>
          </Label>
        </Col>
        <Col xs={12} className="filter-container">
          <Input
            className="form-group"
            placeholder="Search RPIN or Orchard Name..."
            value={propertyFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPropertyFilter(e.target.value)
            }
          />
          <Button
            color="link"
            className={`filter-x ${
              propertyFilter.trim() === "" ? "hide" : "show"
            }`}
            onClick={() => setPropertyFilter("")}
          >
            <Icon icon={x} size={32} />
          </Button>
        </Col>
        <Col xs={12}>
          {!props.properties.loading && properties && !isEmpty(properties)
            ? renderProperties()
            : false}
          {props.properties.loading && <LoadingPage />}
        </Col>
        <Col
          xs={12}
          className={`filter-text ${
            propertyFilter.trim() === "" ? "hide" : "show"
          }`}
        >
          Some results are filtered
          <br />
          ...
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  run,
  auth,
  properties,
}: {
  run: IRunReducer;
  auth: IAuthReducer;
  properties: IPropertyReducer;
}) => {
  return { run, auth, properties };
};

export default connect(mapStateToProps, {
  GetPropertyGroup,
  GetAllLures,
  SelectProperty,
  GetAllTrapStatuses,
})(PropertyGroupPage);
