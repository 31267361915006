import { chain, each, find, keyBy, map } from "lodash";
import moment from "moment";
import {
  LURES_FETCH_ERROR,
  LURES_FETCH_IN_PROGRESS,
  LURES_FETCH_SUCCESS,
} from "../../actions/lures/ActionTypes";
import {
  PROPERTYGROUP_FETCH_ERROR,
  PROPERTYGROUP_FETCH_IN_PROGRESS,
  PROPERTYGROUP_FETCH_SUCCESS,
  SELECT_PROPERTY,
  SELECT_TRAP,
} from "../../actions/properties/ActionTypes";
import {
  OFFLINE_SYNC_CLEAR,
  OFFLINE_SYNC_REMOVE,
  OFFLINE_SYNC_RETRY_FAILURE,
  OFFLINE_SYNC_SUCCESS,
  TRAPS_GET_STATUS_ERROR,
  TRAPS_GET_STATUS_IN_PROGRESS,
  TRAPS_GET_STATUS_SUCCESS,
  TRAP_GET_INFO_SUCCESS,
  TRAP_UPDATE_ERROR,
  TRAP_UPDATE_PENDING,
  TRAP_UPDATE_SUCCESS,
} from "../../actions/traps/ActionTypes";

const generateInitialState = (): IPropertyReducer => {
  const state: IPropertyReducer = {
    loading: false,
    error: null,
    properties: {},
    lures: {},
    traps: {},
    updates: {}, // track update id's against trap ids
    activeProperty: null,
    activeTrap: null,
  };
  return state;
};

const INIT = generateInitialState();

export default (state = INIT, action: any): IPropertyReducer => {
  switch (action.type) {
    case PROPERTYGROUP_FETCH_IN_PROGRESS:
    case LURES_FETCH_IN_PROGRESS:
    case TRAPS_GET_STATUS_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case PROPERTYGROUP_FETCH_SUCCESS:
      // We have to cast it to unknown to avoid assigning raw values twice
      // and avoid it giving us compiler errors for mapping arrays to objects
      const properties = keyBy(action.payload, "id") as unknown as {
        [key: string]: IProperty;
      };
      return {
        ...state,
        loading: true,
        properties,
      };
    case PROPERTYGROUP_FETCH_ERROR:
    case LURES_FETCH_ERROR:
    case TRAPS_GET_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECT_PROPERTY:
      return {
        ...state,
        activeProperty: action.payload,
      };
    case SELECT_TRAP:
      if (action.payload.id === state.activeTrap?.id) return { ...state };
      return {
        ...state,
        activeTrap: action.payload,
      };
    case LURES_FETCH_SUCCESS:
      const mappedLures = map(action.payload, (lure: ILure) => {
        try {
          const insects = keyBy(lure.insects, "pivot.trap_lure_id");
          return {
            ...lure,
            insects,
          };
        } catch (err) {
          console.error(err);
        }
      });
      const lures = keyBy(mappedLures, "id") as unknown as {
        [key: string]: ILure;
      };
      return {
        ...state,
        loading: false,
        lures,
      };
    case OFFLINE_SYNC_CLEAR:
    case OFFLINE_SYNC_SUCCESS:
      const clearedTraps: { [key: string]: ITrap } = {};
      each(state.traps, (trap, id) => {
        clearedTraps[id] = { ...trap, update_pending: false };
      });
      return {
        ...state,
        traps: clearedTraps,
      };
    case OFFLINE_SYNC_REMOVE:
      const _updateId = action.payload.updateId;
      const updatedTraps: { [key: string]: ITrap } = {};
      each(state.traps, (trap, id) => {
        if (Number(id) === _updateId) {
          updatedTraps[id] = { ...trap, update_pending: false };
        } else {
          updatedTraps[id] = trap;
        }
      });
      return { ...state, traps: updatedTraps };
    case TRAPS_GET_STATUS_SUCCESS:
      const data = action.payload.data as ITrap[];
      const traps = chain(data)
        .map((trap) => {
          const updated = moment(trap?.last_monitored_date);
          // If our cached version is newer than the server, keep the cached version
          const cached = moment(state.traps[trap.id]?.last_monitored_date);

          if (cached.isAfter(updated) && state.traps[trap.id]) {
            return state.traps[trap.id];
          }
          const today = moment();
          const updatedToday = trap?.last_monitored_date
            ? updated.isSame(today, "day")
            : false;
          // Arguably updates cannot happen in the future, so
          // if an update is not today, it is yesterday or older,
          // and we can assume it is due.
          trap.status = {
            due: !updatedToday,
            done: updatedToday,
          };
          const existingTrap = state.traps[trap.id] || {};
          return { ...existingTrap, ...trap };
        })
        .compact()
        .keyBy("id")
        .value();
      const currentActiveTrap = find(traps, (trap) => {
        return trap.id === state.activeTrap?.id;
      });
      return {
        ...state,
        loading: false,
        traps,
        activeTrap: {
          ...state.activeTrap,
          status: currentActiveTrap?.status || { due: true, done: false },
        } as IActiveTrap,
      };
    case TRAP_GET_INFO_SUCCESS:
      const trapInfo: IGetTrapPayload = action.payload.trap;
      const last: ITrapMonitorRecord = trapInfo.LastMonitoringRecord;
      const lastUpdate = moment(trapInfo.LastMonitoredDate, "YYYY-MM-DD");
      const today = moment();
      const pendingPostUpdates = action.payload.pendingUpdates.postUpdates;
      const pendingPutUpdates = action.payload.pendingUpdates.putUpdates;
      const hasPendingUpdates =
        pendingPostUpdates[trapInfo.id] || pendingPutUpdates[trapInfo.id];
      // If the last updated day is not today then reset form.
      if (!lastUpdate.isSame(today, "day") && !hasPendingUpdates) {
        const activeTrap: any = {
          ...state.traps[trapInfo.id],
          operator: last?.operator,
          location: {
            latitude: last?.location?.coordinates[0] || 0,
            longitude: last?.location?.coordinates[1] || 0,
          },
          code: trapInfo.code,
          charged_at: trapInfo.charged_at,
          created_at: trapInfo.created_at,
          deleted_at: trapInfo.deleted_at,
          id: trapInfo.id,
          update_id: last?.id,
          location_description: trapInfo.location_description,
          name: trapInfo.name,
          setup_at: trapInfo.setup_at,
          trap_lure_id: trapInfo.trap_lure_id,
          trapping_zone_id: trapInfo.trapping_zone_id,
          updated_at: last?.updated_at,
          last_monitored_date: lastUpdate.format("YYYY-MM-DD"),
          update_pending: false,
          visited_at: last?.visited_at,
          visited_date: last?.visited_date,
          amount: 0,
          lure_changed: false,
          base_changed: false,
          comments: "",
          status: {
            due: true,
            done: false,
          },
        };
        return {
          ...state,
          activeTrap,
          traps: {
            ...state.traps,
            [Number(activeTrap.id)]: activeTrap,
          },
        };
      }
      if (!state.activeTrap || hasPendingUpdates) return { ...state };
      const activeTrap: IActiveTrap = {
        ...state.activeTrap,
        operator: last?.operator,
        location: {
          latitude: last?.location?.coordinates[0] || 0,
          longitude: last?.location?.coordinates[1] || 0,
        },
        charged_at: trapInfo.charged_at,
        created_at: trapInfo.created_at,
        deleted_at: trapInfo.deleted_at,
        id: trapInfo.id,
        update_id: last?.id,
        location_description: trapInfo.location_description,
        name: trapInfo.name,
        setup_at: trapInfo.setup_at,
        trap_lure_id: trapInfo.trap_lure_id,
        trapping_zone_id: trapInfo.trapping_zone_id,
        updated_at: last?.updated_at,
        amount: last?.amount,
        update_pending: state.activeTrap?.update_pending ? true : false,
        last_monitored_date: lastUpdate.format("YYYY-MM-DD"),
        comments: last?.comments,
        base_changed: last?.base_changed > 0,
        lure_changed: last?.lure_changed > 0,
        visited_at: last?.visited_at,
        visited_date: last?.visited_date,
      };
      return {
        ...state,
        activeTrap: activeTrap,
        traps: {
          ...state.traps,
          [Number(activeTrap.id)]: activeTrap,
        },
      };
    case TRAP_UPDATE_PENDING:
      const updates = action.payload as { [key: number]: ITrapPost };
      const cachedTraps: { [key: number]: IActiveTrap } = {};
      each(updates, (update, id) => {
        const updatedTrap: IActiveTrap = {
          ...state.traps[update.trap_id],
          amount: update.amount,
          update_pending: true,
          update_id: Number(id),
          visited_date: update.visited_date,
          visited_at: update.visited_at,
          lure_changed: update.lure_changed,
          base_changed: update.base_changed,
          operator: update.operator,
          comments: update.comments || "",
          location: update.location,
          last_base_changed_date: moment().format("YYYY-MM-DD"),
          last_lure_changed_date: moment().format("YYYY-MM-DD"),
          last_monitored_date: moment().format("YYYY-MM-DD"),
          status: {
            due: false,
            done: true,
          },
        };
        cachedTraps[Number(id)] = updatedTrap;
      });
      return {
        ...state,
        traps: {
          ...state.traps,
          ...cachedTraps,
        },
      };
    case TRAP_UPDATE_ERROR:
    case TRAP_UPDATE_SUCCESS:
      const update: ITrapPost = action.payload.error
        ? action.payload.update
        : action.payload.trap;
      const updateId: number | undefined = action.payload.updateId;
      const updatedTrap: IActiveTrap = {
        ...state.traps[update.trap_id],
        amount: update.amount,
        update_pending: action.payload.error ? true : false,
        update_id: Number(updateId),
        visited_date: update.visited_date,
        visited_at: update.visited_at,
        lure_changed: update.lure_changed,
        base_changed: update.base_changed,
        operator: update.operator,
        comments: update.comments || "",
        location: update.location,
        last_base_changed_date: moment().format("YYYY-MM-DD"),
        last_lure_changed_date: moment().format("YYYY-MM-DD"),
        last_monitored_date: moment().format("YYYY-MM-DD"),
        status: {
          due: false,
          done: true,
        },
      };
      const existing =
        state.updates && state.updates[updatedTrap.last_monitored_date]
          ? state.updates[updatedTrap.last_monitored_date]
          : {};
      return {
        ...state,
        traps: {
          ...state.traps,
          [update.trap_id]: updatedTrap,
        },
        activeTrap: updatedTrap,
        updates: {
          [updatedTrap.last_monitored_date]: {
            ...existing,
            [updatedTrap.id]:
              updateId ||
              (state.updates[updatedTrap.last_monitored_date]
                ? state.updates[updatedTrap.last_monitored_date][updatedTrap.id]
                : undefined),
          },
        },
      };
    case OFFLINE_SYNC_RETRY_FAILURE:
      // Set 'status' of 'traps' specified in 'trapsIds' to due.
      const trapsToSetAsDue = action.payload.trapIds as Array<string>;
      const newTraps = {} as { [key: string]: ITrap };
      for (const trapId of Object.keys(state.traps)) {
        const trap = state.traps[trapId];
        const status = trapsToSetAsDue.includes(trap.id.toString())
          ? {
              done: false,
              due: true,
            }
          : trap.status;
        newTraps[trapId] = { ...trap, status };
      }
      return {
        ...state,
        traps: newTraps,
      };
    default:
      return state;
  }
};
