import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer/AuthReducer';
import PropertyReducer from "./PropertyReducer/PropertyReducer";
import QueueReducer from "./QueueReducer/QueueReducer";
import RunReducer from "./RunReducer/RunReducer";
import UIReducer from "./UIReducer/UIReducer";
const rootReducer = combineReducers({
  auth: AuthReducer,
  run: RunReducer,
  properties: PropertyReducer,
  queue: QueueReducer,
  ui: UIReducer,
});

export default rootReducer;
