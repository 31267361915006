import React, { KeyboardEvent, useState } from "react";
import { GroupBase, SingleValue, StylesConfig, ThemeConfig } from "react-select";
import CreateableSelect from "react-select/creatable";
import { LoginType } from "../../types/auth";
import { PastRuns } from "../../types/run";

interface Props {
	pastRuns: PastRuns;
	setPastRuns: (value: PastRuns) => void
	styles?: StylesConfig<{
		value: string;
		label: string;
	}, false, GroupBase<{
		value: string;
		label: string;
	}>> | undefined
	theme?: ThemeConfig | undefined
	onChange: (newValue: SingleValue<{
		value: string;
		label: string;
	}>) => void;
	loginType: LoginType | null;
	loading: boolean;
	onCreate: Function;
}

const RunNumberSelect = ({
	pastRuns,
	setPastRuns,
	styles,
	theme,
	onChange,
	loginType,
	loading,
	onCreate
}: Props) => {
	const [controlPressed, setControlPressed] = useState(false);

	const options = pastRuns.map(pr => ({
		value: pr.passcode, label: `${pr.passcode} - ${pr.name}`
	}));

	const noOptionsMessage = (inputValue: string) => {
		if (loginType === LoginType.Grower) {
			return inputValue.length > 0 && inputValue.length < 9 ? 'Too short...' :
				inputValue.length > 9 ? 'Too long...' :
					'No passcodes yet...';
		} else {
			return 'No passcodes yet...';
		}
	}

	const isValidNewOption = (inputValue: string) => {
		// Current value does not equal an existing previously inputted value.
		if (pastRuns.find(pr => pr.passcode === inputValue)) return false;
		if (loginType === LoginType.Grower) {
			return inputValue.length === 9;
		}
		return inputValue.length > 0;
	}

	const handleNumberOnlyInput = async (e: KeyboardEvent<HTMLInputElement>) => {
		e.persist();
		if (e.nativeEvent.code === 'ControlLeft' ||
			e.nativeEvent.code === 'ControlRight') {
			// Prevent paste when clipboard isn't a number.
			const clipboard = await navigator.clipboard.readText();
			if (!/^\d+$/.test(clipboard)) {
				e.preventDefault();
				return;
			}
			setControlPressed(true);
			return;
		}
		if (controlPressed) {
			if (e.nativeEvent.code === 'KeyV') {
				setControlPressed(false)
				return;
			}
		}
		if (!e.nativeEvent.code.includes('Digit') &&
			!e.nativeEvent.code.includes('Numpad') &&
			e.nativeEvent.code !== 'Backspace' &&
			e.nativeEvent.code !== 'ArrowLeft' &&
			e.nativeEvent.code !== 'ArrowRight') {
			e.preventDefault();
		}
	};

	return (
    <CreateableSelect
      styles={styles}
      theme={theme}
      options={options}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      placeholder={
        loginType === LoginType.Credentials
          ? "Enter run number to get started..."
          : "Enter a passcode to get started..."
      }
      formatCreateLabel={(inputValue) => "Start run with - " + inputValue}
      isValidNewOption={isValidNewOption}
      onCreateOption={(value) => {
        return onCreate(value);
      }}
      noOptionsMessage={({ inputValue }) => noOptionsMessage(inputValue)}
      isDisabled={loading}
      isClearable
      onKeyDown={handleNumberOnlyInput}
    />
  );
};

export default RunNumberSelect;