export const translateStatus = (status: ITrapStatus): IListItemStatus => {
  if  (status.due === true) {
    return {
      label: "Due",
      color: "danger",
    };
  }
  if  (status.done === true) {
    return {
      label: "Done",
      color: "success",
    };
  }
  return {
    label: "Unknown",
    color: "primary",
  };
};
