import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { compose } from "redux";
import { Logout } from "../../actions/auth/Auth";
import { useGetContext } from "../../hooks/useGetContext";
import "../../styles/LoginPage.scss";
import { LoginType } from '../../types/auth';

const LogoutPage: React.FC<ILogoutPageProps> = (props: ILogoutPageProps) => {
  const history = useHistory();
  const { theme } = useGetContext();

  useEffect(() => {
    props.Logout();
    if (props.auth.loginType === LoginType.Credentials) {
      history.push("/login");
    } else {
      history.push(`/${props.auth.tenantName}/grower-login`);
    }
    //theme?.clear(); <-- NOTE: This may be needed 
  });

  return (
    <div className={`fill flex center login-page tenant-${props.auth.tenantName || 'none'}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="blob top"
      >
        <path
          fillOpacity="1"
          fill={theme?.theme.tenantDarkerColour}
          d="M0,96L34.3,80C68.6,64,137,32,206,32C274.3,32,343,64,411,106.7C480,149,549,203,617,197.3C685.7,192,754,128,823,128C891.4,128,960,192,1029,202.7C1097.1,213,1166,171,1234,181.3C1302.9,192,1371,256,1406,288L1440,320L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>
      <Row>
        <Col xs={12}>
          <h3>Goodbye!</h3>
        </Col>
      </Row>

      <img src="/tree.png" className="tree" alt="" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="blob bottom"
        viewBox="0 0 1440 320"
      >
        <path
          fillOpacity="1"
          fill={theme?.theme.tenantDarkerColour}
          d="M0,128L34.3,117.3C68.6,107,137,85,206,90.7C274.3,96,343,128,411,128C480,128,549,96,617,112C685.7,128,754,192,823,208C891.4,224,960,192,1029,181.3C1097.1,171,1166,181,1234,170.7C1302.9,160,1371,128,1406,112L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

const mapStateToProps = ({ auth }: { auth: IAuthReducer }) => {
  return { auth };
};

export default compose<React.ComponentType<Partial<ILoginPageProps>>>(
  connect(mapStateToProps, { Logout })
)(LogoutPage);
