import { createContext } from "react";

export interface Theme {
	tenantColour: string;
	tenantDarkerColour: string;
}

export interface IThemeContext {
	theme: Theme;
	update: (theme: Partial<Theme>) => void;
	clear: () => void;
}

export const ThemeContext = createContext<IThemeContext | null>(null);