import { noop } from "lodash";
import React from "react";
import Icon, { IconProp } from "react-icons-kit";
import { Button } from "reactstrap";

const _renderIcon = (icon: IconProp) => {
  if (!icon) {
    return false;
  }
  return (
    <div>
      <Icon icon={icon} />{" "}
    </div>
  );
};

const ToastButton: React.FC<ToastButtonProps> = ({
  action,
  icon,
  label,
}: ToastButtonProps) => {
  return (
    <Button className="toast-btn" onClick={() => (action ? action() : noop())}>
      {_renderIcon(icon)}
      {label}
    </Button>
  );
};

export default ToastButton;
