import { apm } from "@elastic/apm-rum";
import AWS from "aws-sdk";
import axios, { AxiosError, AxiosResponse } from "axios";
import React from "react";
import { toast } from "react-toastify";
import { SyncError } from "./errors";
import { DEV_MODE } from "./util";

interface Secret {
  url: string;
  key: string;
}

/**
 * Gets the Elastic credentials for sending indices from
 * AWS Secrets Manager.
 *
 * @returns The Elastic credentials for sending index.
 */
const getElasticTrappingAppCreds = async (): Promise<Secret> => {
  const secretsManager = new AWS.SecretsManager();
  const secretEnvironment =
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? "staging"
      : "production";
  const secret_name = `${secretEnvironment}_trapping_app_elastic_credentials`;
  try {
    const params = {
      SecretId: secret_name,
    };
    const secretResponse = await secretsManager
      .getSecretValue(params)
      .promise();
    // Parse and return the secret value
    if (secretResponse.SecretString) {
      const secret = JSON.parse(secretResponse.SecretString) as Secret;
      return secret;
    } else {
      throw new Error("No secret found.");
    }
  } catch (error) {
    if (DEV_MODE) console.error(error);
    throw new Error("Could not fetch secret for logging failed records.");
  }
};

/**
 * Logs the given records to Elastic.
 * If a record fails to send then get the trap name from traps.
 *
 * @param userId The current authenticated user.
 * @param records The failing records.
 * @param traps All traps that the user has access to. This is used as part of an error message.
 */
export const logFailedRecords = async (
  properties: IPropertyReducer,
  userId: number,
  records: { [key: string]: ITrapPost },
  traps: {
    [key: string]: ITrap;
  }
) => {
  // Get the URL and key for sending log data.
  let elasticUrl;
  let elasticKey;
  try {
    const { url, key } = await getElasticTrappingAppCreds();
    elasticUrl = url;
    elasticKey = key;
  } catch (error) {
    if (DEV_MODE) console.error(error);
    else apm.captureError(error as Error);
  }
  // Create request.
  const url = `${elasticUrl}/trapping-app-logging/_doc`;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `ApiKey ${elasticKey}`,
  };
  const recordsArray = [];
  for (const key of Object.keys(records)) {
    recordsArray.push(records[key]);
  }
  const failedTraps: {
    rpin: string;
    trapping_zone_name: string;
    trap_name_code: string;
    moth_count: number;
  }[] = [];
  recordsArray.forEach(async (record) => {
    const payload = {
      userId,
      record,
    };
    // Send request to store failed records in Elastic.
    try {
      const response: AxiosResponse<any> = await axios.post(url, payload, {
        headers,
      });
      if (response.status >= 200 && response.status < 300) {
      }
    } catch (error) {
      if (record.id) {
        let trapping_zone: ITrappingZone | undefined;
        Object.keys(properties.properties).forEach((key) => {
          const trapping_zones = properties.properties[key].trapping_zones;
          const tz = trapping_zones.find(
            (tz) => tz.id === properties.traps[record.trap_id].trapping_zone_id
          );
          if (!trapping_zone && tz) trapping_zone = tz;
        });
        const trap = traps[record.trap_id];
        failedTraps.push({
          rpin: trapping_zone?.property_id
            ? properties.properties[trapping_zone?.property_id]?.identifier
            : "-",
          trapping_zone_name: trapping_zone?.name || "-",
          trap_name_code: `${trap.name} (${trap.code})`,
          moth_count: record.amount,
        });
      }
      if (DEV_MODE) console.error(error);
      else apm.captureError(error as Error);
    }
  });
  if (failedTraps.length === 0) {
    toast.info(
      "Your data has not been lost. Contact support to resolve this.",
      { autoClose: false }
    );
  } else if (failedTraps.length === recordsArray.length) {
    toast.error(
      <>
        <p className="p-0 m-0">
          Failed to save your data for retrieval. Please take a screenshot.
          Re-enter data or contact support:
        </p>
        {failedTraps.map((ft) => {
          return (
            <div>
              <p className="p-0 m-0">RPIN: {ft.rpin}</p>
              <p className="p-0 m-0">Block: {ft.trapping_zone_name}</p>
              <p className="p-0 m-0">Trap: {ft.trap_name_code}</p>
              <p className="p-0 m-0">Moths: {ft.moth_count}</p>
            </div>
          );
        })}
      </>,
      { autoClose: false }
    );
  } else {
    toast.error(
      <>
        <p className="p-0 m-0">
          Could not save the following data for analysis. Please take a
          screenshot and re-enter:
        </p>
        {failedTraps.map((ft) => {
          return (
            <div>
              <p className="p-0 m-0">RPIN: {ft.rpin}</p>
              <p className="p-0 m-0">Block: {ft.trapping_zone_name}</p>
              <p className="p-0 m-0">Trap: {ft.trap_name_code}</p>
              <p className="p-0 m-0">Moths: {ft.moth_count}</p>
            </div>
          );
        })}
      </>,
      { autoClose: false }
    );
  }
};

/**
 * Logs the given error to Elastic is online.
 *
 * @param error
 * @param isOnline
 */
export const logTraplogApiError = (
  error: Error | AxiosError | SyncError,
  isOnline?: boolean
) => {
  if (isOnline && !DEV_MODE) {
    apm.captureError(error);
  }
  if (DEV_MODE) console.error(error, "isOnline " + isOnline);
};
