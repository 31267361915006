import {
  AUTH_GET_USER_ERROR,
  AUTH_GET_USER_IN_PROGRESS,
  AUTH_GET_USER_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_IN_PROGRESS,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT
} from "../../actions/auth/ActionTypes";

const generateInitialState = (): IAuthReducer => {
  // Let's try see if we can get a token from localStorage;
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const state: IAuthReducer = {
    loginType: null,
    validTenants: ['nzap'],
    tenantName: '',
    service: '',
    seasonalDatabase: '',
    loading: false,
    isAuthenticated: !!accessToken,
    isPopulated: false,
    error: null,
    access: null,
    accessToken: accessToken,
    refreshToken: refreshToken,
    id: 0,
    created_at: '',
    updated_at: '',
    name: '',
    email: '',
    email_verified_at: '',
    remember_token: null,
    role_id: 0,
    deleted_at: null,
    api_token: null,
    send_reports: 0,
    role: {
      id: 0,
      created_at: '',
      updated_at: '',
      name: '',
      description: '',
    }
  }
  return state;
}

const INIT = generateInitialState();

export default (state = INIT, action: any): IAuthReducer => {
  switch (action.type) {
    case AUTH_LOGOUT:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      const newState = generateInitialState();
      return { ...newState };
    case AUTH_GET_USER_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      }
    case AUTH_LOGIN_IN_PROGRESS:
      return {
        ...INIT,
        loading: true,
      };
    case AUTH_LOGIN_SUCCESS:
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("accessTokenExpires", action.payload.expires);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      return {
        ...state,
        loginType: action.payload.loginType,
        tenantName: action.payload.tenantName,
        service: action.payload.service,
        seasonalDatabase: action.payload.seasonalDatabase,
        loading: false,
        error: null,
        isAuthenticated: true,
        name: action.payload.name,
        access: action.payload.access,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case AUTH_GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isPopulated: true,
        ...action.payload
      };
    case AUTH_LOGIN_ERROR:
    case AUTH_GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false,
        name: '',
        accessToken: null,
        refreshToken: null,
      };
    default:
      return state;
  }
};
