export const AUTH_URL =
  process.env.REACT_APP_TRAPLOG_AUTH_URL;
export const BASE_URL =
  process.env.REACT_APP_TRAPLOG_BASE_URL;
export const HEADER_SERVICE =
  process.env.REACT_APP_TRAPLOG_SERVICE;
export const SEASONAL_DB =
  process.env.REACT_APP_TRAPLOG_SEASONAL_DB;
export const NZAP_SERVICE =
  process.env.REACT_APP_NZAP_SERVICE;
export const NZAP_SEASONAL_DB =
  process.env.REACT_APP_NZAP_SEASONAL_DB;
